import React, { useState, useEffect } from "react";
import SearchbarTop from "../../common/SearchbarTop";
import view from "../../../images/view.svg";
import deleteIcon from "../../../images/deletee.svg";
import editIcon from "../../../images/edit.svg";
import axios from "axios";
import Pagination from "../../common/Pagination";
import { useNavigate } from "react-router-dom";
import newicon from "../../../images/newicon.svg";
import damageicon from "../../../images/damageicon.svg";
import Loading from "../../common/loading";
import { toast } from "react-toastify";
import EmrequestpviewModel from "./EmrequestpviewModel";
import DeleteItemModal from "../../common/DeleteItemModal";

const EmpRequest = () => {
  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [modalData, setModalData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const navigate = useNavigate();
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  // console.log("emprewuest..", data);

  const fetchData = async () => {
    try {
      const response = await axios.get("/api/get_user_requests");
      const requests = response.data.map(
        (item) => item.user_assetitems_request
      );
      const sortedRequests = requests.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });

      setData(sortedRequests);
      setTotalItems(sortedRequests.length);
      setFilteredData(sortedRequests);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearchInputChange = (searchInput) => {
    const lowerCaseInput = searchInput.toLowerCase();
    const filterData = (item) =>
      JSON.stringify(item).toLowerCase().includes(lowerCaseInput);
    const newFilteredData = data.filter(filterData);
    setFilteredData(newFilteredData);
    setCurrentPage(1);
    setTotalItems(newFilteredData.length);
  };
  useEffect(() => {
    const fetchDataById = async () => {
      if (selectedItemId) {
        try {
          const response = await axios.get(
            `/api/get_user_assetitems_request/${selectedItemId}`
          );
          setModalData(response.data.user_assetitems_request);
          setIsModalOpen(true);
        } catch (error) {
          console.error(error);
        }
      }
    };

    fetchDataById();
  }, [selectedItemId]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleVieweditItemClick = (id) => {
    navigate(`/employee/request/addrequestedit/${id}`);
  };
  const handleViewItemClick = (id) => {
    setSelectedItemId(id);
    // navigate(`/employee/request/addrequestedit/${id}`);
  };

  const handleDeleteClick = (item) => {
    setItemToDelete(item);
    setIsDeleteModalVisible(true);
  };

  const confirmDelete = async () => {
    try {
      const response = await axios.delete(
        `/api/delete_user_request/${itemToDelete._id}`
      );

      if (response.status === 200) {
        // setTotalItems((prevTotal) => prevTotal - 1);
        fetchData();
        toast.success("Item successfully deleted");
        setItemToDelete(null);
        setIsDeleteModalVisible(false);
      }
    } catch (error) {
      toast.error("Error deleting item:");
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalData(null);
  };

  const handleAddRequest = () => {
    navigate("/employee/request/addrequestform");
  };

  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="section">
      <div className="container">
        <SearchbarTop
          title="Employee Request"
          onSearchInputChange={handleSearchInputChange}
        />
        <div className="action_btns">
          <button className="btn" onClick={handleAddRequest}>
            ADD REQUEST
          </button>
        </div>
        {loading ? (
          <Loading />
        ) : (
          <>
            {paginatedData.length > 0 ? (
              <div className="table-container">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">ITEMS</th>
                      <th scope="col">PRIORITY</th>
                      <th scope="col">STATUS</th>
                      <th scope="col">TYPE</th>
                      <th scope="col">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedData.map((item, index) => (
                      <tr key={item._id} className="table_tr">
                        <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                        <td>
                          {item.type.name === "Damage"
                            ? item.asset_inventory?.item_name
                            : item.item?.item_name}
                        </td>
                        <td>
                          {item.priority.name === "Low" ? (
                            <>
                              <span className="priority_dot low"></span>
                              <span className="priority_btn low"> Low </span>
                            </>
                          ) : item.priority.name === "Medium" ? (
                            <>
                              <span className="priority_dot mediam"></span>
                              <span className="priority_btn medium">
                                {" "}
                                Medium{" "}
                              </span>
                            </>
                          ) : item.priority.name === "High" ? (
                            <>
                              <span className="priority_dot high"></span>
                              <span className="priority_btn high"> High </span>
                            </>
                          ) : null}
                        </td>
                        <td>
                          {item.status.status === "Hold" ? (
                            <h6 className="btn_status Hold">Hold</h6>
                          ) : item.status.status === "Complete" ? (
                            <h6 className="btn_status complate">Complete</h6>
                          ) : item.status.status === "Pending" ? (
                            <h6 className="btn_status Pending">Pending</h6>
                          ) : item.status.status === "Reject" ? (
                            <h6 className="btn_status reject">Reject</h6>
                          ) : item.status.status === "In Active" ? (
                            <h6 className="btn_status inactive">In Active</h6>
                          ) : item.status.status === "In Progress" ? (
                            <h6 className="btn_status inprogress">
                              In Progress
                            </h6>
                          ) : null}
                        </td>
                        <td>
                          {item.type.name === "New" ? (
                            <div className="btn_type new_button">
                              <img src={newicon} alt="New" className="icon" />
                              <h6> New</h6>
                            </div>
                          ) : item.type.name === "Damage" ? (
                            <div className="btn_type damage_button">
                              <img
                                src={damageicon}
                                alt="Damaged"
                                className="icon"
                              />
                              <h6> Damaged</h6>
                            </div>
                          ) : null}
                        </td>
                        <td>
                          <img
                            src={view}
                            alt="view"
                            className="view action_icon"
                            onClick={() => handleViewItemClick(item._id)}
                            style={{ cursor: "pointer" }}
                          />
                          {item?.status?.status === "Pending" && (
                            <img
                              src={editIcon}
                              alt="editIcon"
                              className="editIcon action_icon"
                              onClick={() => handleVieweditItemClick(item._id)}
                            />
                          )}
                          {/* <img
                            src={editIcon}
                            alt="editIcon"
                            className="editIcon action_icon"
                            onClick={() => handleVieweditItemClick(item._id)}
                          /> */}

                          <img
                            src={deleteIcon}
                            alt="delete"
                            className="deleteIcon action_icon"
                            onClick={() => handleDeleteClick(item)}
                            style={{ cursor: "pointer" }}
                          />
                        </td>
                      </tr>
                    ))}
                    {isModalOpen && modalData && (
                      <EmrequestpviewModel
                        item={modalData}
                        onClose={handleCloseModal}
                      />
                    )}
                  </tbody>
                </table>
              </div>
            ) : (
              <h2 className="data_noavailble">Data Not Available</h2>
            )}
            <Pagination
              currentPage={currentPage}
              totalItems={totalItems}
              itemsPerPage={itemsPerPage}
              onPageChange={handlePageChange}
            />
            {isDeleteModalVisible && itemToDelete && (
              <DeleteItemModal
                data={itemToDelete}
                onDelete={confirmDelete}
                onClose={() => setIsDeleteModalVisible(false)}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default EmpRequest;
