import React from "react";
import PropTypes from "prop-types";
import closeicon from "../../images/close-icon.svg";
import moment from "moment";
import FormattedDate from "../common/FormattedDate";

const ItemViewModal = ({ item, onClose }) => {
  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close-icon" onClick={onClose}>
          <img src={closeicon} alt="New" className="" />
        </span>

        <p>
          <strong>Items : </strong> {item.item.item_name}
        </p>

        <p>
          <strong>Purchased At : </strong>
          {/* {item.purchase_date} */}
          <FormattedDate date={item.purchase_date} />
          {/* {moment(item.purchase_date, "DD-MM-YYYY").format("DD-MM-YYYY")} */}
        </p>
        <p>
          <strong>Type : </strong> {item.type?.type_name}
        </p>
        <p className="detailed_fix">
          <strong>Device Details : {item.details}</strong>
        </p>
      </div>
    </div>
  );
};

ItemViewModal.propTypes = {
  item: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    item: PropTypes.shape({
      item_name: PropTypes.string.isRequired,
    }).isRequired,
    purchase_date: PropTypes.string.isRequired,
    type: PropTypes.shape({
      type_name: PropTypes.string.isRequired,
    }).isRequired,
    qrcode_path: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ItemViewModal;
