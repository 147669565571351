import React, { useEffect, useState } from "react";
import SearchbarTop from "../../common/SearchbarTop2";
import axios from "axios";
import { useParams } from "react-router-dom";
import downloadIcon from "../../../images/download.png";
import Loading from "../../common/loading";
const imgStyle = {
  border: "3px solid #000",
  borderRadius: "16px",
  position: "relative", // To position the download icon absolutely
};

// Function to fetch and download a single image
const downloadImage = async (url, name) => {
  try {
    const response = await axios.get(url, { responseType: "blob" });
    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error downloading image:", error);
  }
};
// Function to extract image URLs
const extractImageUrls = (data) => {
  return data.map((item) => `/${item.qrcode_path}`);
};
// Function to download all images sequentially
const downloadAllImages = (imageUrls) => {
  imageUrls.forEach((url, index) => {
    setTimeout(() => {
      downloadImage(url, `image_${index + 1}.png`);
    }); // Adjust delay if needed
  });
};
const handlePrint = () => {};
const AssestQrViewScan = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `/api/get_user_assetitems_by_id/${id}`
        );
        // console.log("data...", response.data.user_assetitem.asset_inventory);
        setData(response.data?.user_assetitem.asset_inventory || []);
        // setData(
        //   response.data?.user_assetitems[0]?.user_assetitem?.asset_inventory ||
        //     []
        // );
      } catch (error) {
        console.error("API Error:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);
  // Extract image URLs from the data
  const imageUrls = extractImageUrls(data);
  return (
    <div className="edit-item-form">
      <div className="container">
        <SearchbarTop title="Asset QR Code" />
        {loading ? (
          <Loading />
        ) : (
          <div className="form editform assest_scan">
            <div className="row">
              {data.map((item, index) => (
                <div className="col-lg-3 col-6 px-5 text-center" key={index}>
                  <div style={{ position: "relative" }} className="img_blog">
                    <img
                      src={`/${item.qrcode_path}`}
                      alt={`QR Code for ${item.item_name}`}
                      width="100%"
                      height="100%"
                      className="img-fluid scan_img"
                    />
                    <img
                      src={downloadIcon}
                      alt="Download"
                      className="download-icon img-fluid"
                      onClick={() =>
                        downloadImage(
                          `/${item.qrcode_path}`,
                          `${item.item_name}.png`
                        )
                      }
                      style={{
                        position: "absolute",
                        bottom: 10,
                        right: 10,
                        cursor: "pointer",
                      }}
                    />
                  </div>
                  <h3 className="mt-2">{item.item_name}</h3>
                </div>
              ))}
            </div>
            <div
              className="d-flex align-items-center"
              style={{
                justifyContent: "center",
                gap: "18px",
              }}
            >
              {/* <button type="button" className="btn mt-5" onClick={handlePrint}>
              PRINT
            </button> */}
              <button
                type="button"
                className="btn mt-5"
                onClick={() => downloadAllImages(imageUrls)}
              >
                DOWNLOAD ALL
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default AssestQrViewScan;
