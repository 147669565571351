import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Request from "../HR/Request/Request";
import Inventory from "../HR/Inventory/Inventory";
import AssetQR from "../HR/AssetQR/AssetQR";
import AssignItems from "../HR/AssignItems/Assignitems";
import AssestQrViewScan from "./AssetQR/AssestQrViewScan";
import InventoryAddItemsForm from "./Inventory/InventoryAddItemsForm";
import InventoryEditItemForm from "./Inventory/InventoryEditItemForm";
import AssignItemsForm from "./AssignItems/AssignItemsEditForm";
import AssignItemsEditForm from "./AssignItems/AssignItemsEditForm";
import AllocateItemsForm from "./AssignItems/AllocateItemsForm";
import InventoryAssets from "./Inventory/InventoryAssets";
import Header from "../../Header/Header";

const NavigationHR = () => {
  return (
    <>
      {/* <Header /> */}
      <Routes>
        <Route path="request" element={<Request />} />
        <Route path="inventory" element={<Inventory />} />
        <Route
          path="inventory/inventryedit/:id"
          element={<InventoryEditItemForm />}
        />
        <Route
          path="inventory/inventoryadditem"
          element={<InventoryAddItemsForm />}
        />
        <Route path="inventory/assest" element={<InventoryAssets />} />
        <Route path="assetqr" element={<AssetQR />} />
        <Route path="assetqr/assestqrcode/:id" element={<AssestQrViewScan />} />
        <Route path="assigniitems" element={<AssignItems />} />
        <Route
          path="assigniitems/allocateitem"
          element={<AllocateItemsForm />}
        />
        <Route path="assigniitems/edit/:id" element={<AssignItemsEditForm />} />
      </Routes>
    </>
  );
};

export default NavigationHR;
