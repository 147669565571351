import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import SearchbarTop from "../../common/SearchbarTop2";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Loading from "../../common/loading";
import ThankyouModal from "../../common/ThankyouModal";
// Validation schema
const validationSchema = Yup.object({
  employee: Yup.string().required("Employee is required"),
  department: Yup.string().required("Department is required"),
  // item_id: Yup.string().required("At least one item must be selected"),
});
const AssignItemsEditForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [initialValues, setInitialValues] = useState({
    item_id: "",
    item_name: "",
    employee: "",
    department: "",
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [selectedValues, setSelectedValues] = useState({});
  const [uniqueSubData, setUniqueSubData] = useState([]);
  // Memoized unique categories extraction
  const uniqueCategories = useMemo(() => {
    const items_uniq = items.map((item) => ({
      id: item._id,
      item_name: item.item.item_name,
    }));
    const uniqueMap = new Map();
    items_uniq.forEach((item) => {
      if (!uniqueMap.has(item.item_name)) {
        uniqueMap.set(item.item_name, item);
      }
    });
    return Array.from(uniqueMap.values());
  }, [items]);
  // Fetch data on mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [departmentsResponse, itemsResponse, employeesResponse] =
          await Promise.all([
            axios.get("/api/records/departments"),
            axios.get("/api/get_all_asset"),
            axios.get("/api/employees/list"),
          ]);
        setDepartments(departmentsResponse.data.records || []);
        setItems(itemsResponse.data.inventory || []);
        setEmployees(employeesResponse.data.employees || []);
      } catch (error) {
        setError("Error fetching data");
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  // Fetch asset data when id or items change
  useEffect(() => {
    const fetchAssetData = async () => {
      try {
        const response = await axios.get(
          `/api/get_user_assetitems_by_id/${id}`
        );
        // const asset = response.data.user_assetitems[0]?.user_assetitem;
        // const assetInventory_all = asset?.asset_inventory || {};
        // const assetInventory = asset?.asset_inventory[0] || {};

        const asset = response.data.user_assetitem;
        const assetInventory_all = asset?.asset_inventory || {};
        const assetInventory = asset?.asset_inventory[0] || {};
        const department = asset?.department || {};
        const user = asset?.user_id || {};
        const selected_item_name = assetInventory.item_name || "";
        const selectedItems = items
          .filter((item) => selected_item_name.includes(item.item.item_name))
          .map((item) => item._id);
        setUniqueSubData(
          selectedItems.map((id) => items.find((item) => item._id === id))
        );
        const selectedValues = assetInventory_all.reduce((acc, item) => {
          acc[item.item_name] = {
            id: item.asset_inventory_id,
            details: item.details,
          };
          return acc;
        }, {});
        setSelectedValues(selectedValues);
        setInitialValues({
          item_id: asset?._id || "",
          item_name: assetInventory.item_name || "",
          employee: user.id || "",
          department: department._id || "",
        });
      } catch (error) {
        console.error("Error fetching asset data:", error);
        toast.error("Error fetching asset data.");
      }
    };
    fetchAssetData();
  }, [id, items]);
  // Handle form submission
  const onSubmit = useCallback(
    async (values, { setSubmitting }) => {
      const inventory_ids = Object.values(selectedValues).map(
        (item) => item.id
      );
      const dataToPost = { asset_inventory_ids: inventory_ids };
      try {
        await axios.put(`/api/update_user_assetitems/${id}`, dataToPost, {
          headers: { "Content-Type": "application/json" },
        });
        setIsSubmitted(true);
      } catch (error) {
        console.error("Error updating data:", error);
        toast.error(error.response?.data?.message || "Error updating data.");
      } finally {
        setSubmitting(false);
      }
    },
    [id, selectedValues]
  );
  // Handle item selection
  const handleSelectChange = useCallback(
    (e, setFieldValue) => {
      const selectedOptions = Array.from(e.target.selectedOptions).map(
        (option) => option.value
      );
      const selectedItems = items
        .filter((item) => selectedOptions.includes(item.item.item_name))
        .map((item) => item._id);
      setUniqueSubData(
        selectedItems.map((id) => items.find((item) => item._id === id))
      );
      setFieldValue("item_id", selectedItems);
    },
    [items]
  );
  // Handle radio change
  const handleRadioChange = useCallback((event, details, groupName) => {
    const value = event.target.value;
    setSelectedValues((prevSelectedDetails) => ({
      ...prevSelectedDetails,
      [groupName]: { id: value, details: details },
    }));
  }, []);
  // Handle key delete
  const handleKeyDelete = useCallback((id) => {
    setSelectedValues((prevSelectedValues) => {
      const updatedValues = Object.fromEntries(
        Object.entries(prevSelectedValues).filter(
          ([key, value]) => value.id !== id
        )
      );
      return updatedValues;
    });
  }, []);
  const handleClosePopup = useCallback(() => {
    setIsSubmitted(false);
    navigate(-1);
  }, [navigate]);
  return (
    <div className="edit-item-form">
      <div className="container">
        <SearchbarTop title="Edit Assigned Items" />
        {loading ? (
          <Loading loading={loading} />
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ isSubmitting, setFieldValue, values }) => (
              <div className="form editform">
                <Form>
                  <div className="row">
                    <EmployeeField employees={employees} />
                    <DepartmentField departments={departments} />
                    <ItemsField
                      uniqueCategories={uniqueCategories}
                      values={values}
                      setFieldValue={setFieldValue}
                      handleSelectChange={handleSelectChange}
                      selectedValues={selectedValues}
                      handleKeyDelete={handleKeyDelete}
                    />
                    <ItemDetails
                      uniqueSubData={uniqueSubData}
                      selectedValues={selectedValues}
                      handleRadioChange={handleRadioChange}
                    />
                  </div>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn"
                    style={{ margin: "0 auto", display: "block" }}
                  >
                    {isSubmitting ? "UPDATING..." : "UPDATE"}
                  </button>
                </Form>
              </div>
            )}
          </Formik>
        )}
        {isSubmitted && <ThankyouModal handleok={handleClosePopup} />}
      </div>
    </div>
  );
};
const EmployeeField = ({ employees }) => (
  <div className="col-12">
    <fieldset>
      <legend>Employee</legend>
      <Field as="select" name="employee">
        <option value="" label="Select employee" />
        {employees.map((employee) => (
          <option key={employee.id} value={employee.id}>
            {employee.name}
          </option>
        ))}
      </Field>
      <ErrorMessage name="employee" component="div" className="error-message" />
    </fieldset>
  </div>
);
const DepartmentField = ({ departments }) => (
  <div className="col-6">
    <fieldset>
      <legend>Department</legend>
      <Field as="select" name="department">
        <option value="" label="Select department" />
        {departments.map((department) => (
          <option key={department._id} value={department._id}>
            {department.name}
          </option>
        ))}
      </Field>
      <ErrorMessage
        name="department"
        component="div"
        className="error-message"
      />
    </fieldset>
  </div>
);
const ItemsField = ({
  uniqueCategories,
  values,
  setFieldValue,
  handleSelectChange,
  selectedValues,
  handleKeyDelete,
}) => (
  <div className="col-6">
    <fieldset>
      <legend>Items</legend>
      <Field
        as="select"
        name="item_id"
        value={values.item_name}
        onChange={(e) => handleSelectChange(e, setFieldValue)}
      >
        <option value="" label="Select an item" />
        {uniqueCategories.map((item) => (
          <option key={item.id} value={item.item_name}>
            {item.item_name}
          </option>
        ))}
      </Field>
      <ErrorMessage name="item_id" component="div" className="error-message" />
    </fieldset>
    <div className="selected-items">
      {Object.entries(selectedValues).map(([groupName, detailsObj]) => (
        <div key={groupName} className="selected-item">
          <span>{groupName}</span>
          <span
            onClick={() => handleKeyDelete(detailsObj.id)}
            style={{ cursor: "pointer", color: "red", marginLeft: "5px" }}
          >
            &times;
          </span>
        </div>
      ))}
    </div>
  </div>
);

const ItemDetails = ({ uniqueSubData, selectedValues, handleRadioChange }) => (
  <div className="col-12">
    {uniqueSubData && uniqueSubData.length > 0 && (
      <>
        <legend className="mb-2"> Item Details</legend>
        <div className="d-flex allocate_item_details">
          {uniqueSubData.map((item) => (
            <div key={item._id} className="item">
              <Field
                type="radio"
                name={item.item.item_name}
                checked={selectedValues[item.item.item_name]?.id === item._id}
                value={item._id}
                onChange={(event) =>
                  handleRadioChange(event, item.details, item.item.item_name)
                }
              />
              {item.details}
            </div>
          ))}
        </div>
      </>
    )}
  </div>
);
export default AssignItemsEditForm;
