import React, { createContext, useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  // const [loading, setLoading] = useState(true);
  const [user, setUser] = useState();

  const checkuth = async (token) => {
    // console.log("token....", token);
    const response = await axios.get(`/sso?token=${token}`);

    // console.log("sso Responce", response);
    if (response.status === 401) {
      console.log("Unauthorized, logging out");
      setUser(null);
    } else {
      GetUser();
    }
  };
  const GetUser = useCallback(async () => {
    try {
      const userResponse = await axios.get("/api/employees/sso");
      setUser(userResponse.data);
      // if (userResponse.data.role === "HR") {
      //   navigate("/hr/request");
      // } else if (userResponse.data.role === "Employee") {
      //   navigate("/employee/empitem");
      // } else {
      //   navigate("https://hrems.lemolite.com/dashboard/time-attendance");
      // }
      // console.log("userResponse", userResponse.data);
    } catch (error) {
      console.error("Failed to fetch user data", error);
    }
  }, []);

  useEffect(() => {
    // console.log("useEffect called"); // Debugging log

    GetUser();
  }, [GetUser]);

  return (
    <AuthContext.Provider value={{ checkuth, user }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
