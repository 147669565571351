import React from "react";
import PropTypes from "prop-types";
import closeicon from "../../../images/close-icon.svg";
import img from "../../../images/qrcode.svg";

const EmrequestpviewModel = ({ item, onClose }) => {
  console.log("items.....", item);
  console.log("itemstype.....", item.type.name);

  return (
    <div className="img_model modal">
      <div className="modal_content ">
        {item.type.name === "Damage" && (
          <div className="img_secs w-50">
            <img
              src={`/${item.asset_inventory.qrcode_path}`}
              alt="QR Code"
              width="200px"
              height="200px"
            />
          </div>
        )}

        {item.type.name === "New" && item.images.length > 0 && (
          <div className="img_secs w-50">
            <img
              src={`/${item.images[0]}`}
              alt="QR Code"
              width="200px"
              height="200px"
              style={{
                objectFit: "cover",
              }}
            />
          </div>
        )}
        <div className="content w-50">
          <span className="close-icon" onClick={onClose}>
            <img src={closeicon} alt="Close" />
          </span>
          <p>
            <strong>Name :</strong>
            {item.user.name}
            {/* {item?.type === "New"
              ? item.user.name
              : item.asset_inventory?.item_name} */}
          </p>
          <p>
            <strong>Type : </strong> {item?.type?.name || "N/A"}
          </p>
          <p>
            <strong>Item : </strong>
            {/* {item?.item?.item_name || "N/A"} */}
            {item?.type.name === "New"
              ? item.item?.item_name || "-"
              : item.asset_inventory?.item_name || "-"}
          </p>
          {/* <p>
            <strong>Device Details:</strong> {item?.details || "N/A"}
          </p> */}
          <p>
            <strong>Priority : </strong> {item?.priority?.name || "N/A"}
          </p>
          <p>
            <strong>Status :</strong> {item?.status?.status || "N/A"}
          </p>
        </div>
        <fieldset className="w-100">
          <legend>Items Details</legend>
          <textarea
            type="textarea"
            ame="details"
            rows="2"
            cols="3"
            value={item.details}
            readOnly
          ></textarea>
        </fieldset>
        {item.type.name === "Damage" && item.images.length > 0 && (
          <div className="row w-100">
            {item.images.map((image, index) => (
              <div className="col-4">
                <div className="img_secs" key={index}>
                  <img
                    src={`/${image}`}
                    alt={`Image ${index}`}
                    width="95px"
                    height="95px"
                    style={{
                      objectFit: "cover",
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

EmrequestpviewModel.propTypes = {
  item: PropTypes.shape({
    qrcode_path: PropTypes.string,
    user: PropTypes.shape({
      name: PropTypes.string,
    }),
    department: PropTypes.shape({
      name: PropTypes.string,
    }),
    item: PropTypes.shape({
      item_name: PropTypes.string,
    }),
    type: PropTypes.shape({
      name: PropTypes.string,
    }),
    details: PropTypes.string,
    priority: PropTypes.shape({
      name: PropTypes.string,
    }),
    status: PropTypes.shape({
      status: PropTypes.string,
    }),
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
  }),
  onClose: PropTypes.func.isRequired,
};

export default EmrequestpviewModel;
