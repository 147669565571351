import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SearchbarTop from "../../common/SearchbarTop";
import view from "../../../images/view.svg";
import editIcon from "../../../images/edit.svg";
import deleteIcon from "../../../images/deletee.svg";
import axios from "axios";
import moment from "moment";
import Pagination from "../../common/Pagination";
import Loading from "../../common/loading";
import { toast } from "react-toastify";
import DeleteItemModal from "../../common/DeleteItemModal";
import AssignViewModel from "../AssignItems/AssignViewModel";

const AssignItems = () => {
  const itemsPerPage = 6;
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]); // Initialize as an array
  const [filteredData, setFilteredData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const response = await axios.get("/api/get_user_assetitems");
      const fetchedData = response.data.user_assetitems;
      const sortedData = fetchedData.sort((a, b) => {
        return (
          new Date(b.user_assetitem.created_at) -
          new Date(a.user_assetitem.created_at)
        );
      });
      setData(sortedData);
      setFilteredData(sortedData); // Initialize filteredData with sorted data
    } catch (error) {
      console.error("API Error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const fetchDataById = async () => {
      if (selectedItemId) {
        try {
          const response = await axios.get(
            `/api/get_user_assetitems_by_id/${selectedItemId}`
          );
          setModalData(response.data.user_assetitem);
          setIsModalOpen(true);
        } catch (error) {
          console.error(error);
        }
      }
    };

    fetchDataById();
  }, [selectedItemId]);

  const handleDelete = async (itemId) => {
    try {
      const response = await axios.delete(
        `/api/delete_user_assetitems/${itemId}`
      );
      if (response.status === 200) {
        const { message } = response.data;
        toast.success(message);
      }
      fetchData();
    } catch (error) {
      console.error("Error deleting item:", error);
      toast.error("Error deleting item");
    }
    setIsDeleteModalVisible(false);
  };

  const handleSearchInputChange = (searchInput) => {
    setSearchInput(searchInput);
    const lowerCaseInput = searchInput.toLowerCase();

    const filterData = (item) =>
      JSON.stringify(item).toLowerCase().includes(lowerCaseInput);

    setFilteredData(data.filter(filterData)); // Filter the data array directly
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const currentItems = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleViewItemClick = (id) => {
    setSelectedItemId(id);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalData(null);
    setSelectedItemId(null);
  };

  const handleAllocateClick = () => {
    navigate(`/hr/assigniitems/allocateitem`);
  };

  const handleEditClick = (id) => {
    navigate(`/hr/assigniitems/edit/${id}`);
  };

  return (
    <div className="section">
      <div className="container">
        <SearchbarTop
          title="Allocate / Deallocate Items"
          onSearchInputChange={handleSearchInputChange}
        />
        <div className="action_btns">
          <button className="btn" onClick={handleAllocateClick}>
            ALLOCATE ITEMS
          </button>
        </div>

        {loading ? (
          <Loading loading={loading} />
        ) : (
          <>
            {currentItems.length > 0 ? (
              <div className="table-container">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">NAME</th>
                      <th scope="col">DEPARTMENT</th>
                      <th scope="col">ALLOCATE DATE</th>
                      <th scope="col">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.map((item, index) => {
                      const assetItem = item.user_assetitem;
                      return (
                        <tr key={index} className="table_tr">
                          <td>
                            {(currentPage - 1) * itemsPerPage + index + 1}
                          </td>
                          <td>{assetItem.user_id.name}</td>
                          <td>{assetItem.department.name}</td>
                          <td>
                            {moment(assetItem.created_at).format("DD-MM-YYYY")}
                          </td>
                          <td>
                            <img
                              src={view}
                              alt="view"
                              className="view action_icon"
                              onClick={() => handleViewItemClick(assetItem._id)}
                              style={{ cursor: "pointer" }}
                            />
                            <img
                              src={editIcon}
                              alt="editIcon"
                              className="editIcon action_icon"
                              onClick={() => handleEditClick(assetItem._id)}
                              style={{ cursor: "pointer" }}
                            />
                            <img
                              src={deleteIcon}
                              alt="delete"
                              className="deleteIcon action_icon"
                              onClick={() => {
                                setModalData(assetItem);
                                setIsDeleteModalVisible(true);
                              }}
                              style={{ cursor: "pointer" }}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <h2 className="data_noavailble">Data Not Available</h2>
            )}
            <Pagination
              currentPage={currentPage}
              totalItems={filteredData.length}
              itemsPerPage={itemsPerPage}
              onPageChange={handlePageChange}
            />
            {isModalOpen && modalData && (
              <AssignViewModel item={modalData} onClose={handleCloseModal} />
            )}

            {isDeleteModalVisible && (
              <DeleteItemModal
                data={modalData}
                onDelete={() => handleDelete(modalData._id)}
                onClose={() => setIsDeleteModalVisible(false)}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AssignItems;
