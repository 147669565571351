import React, { useState, useEffect, useContext } from "react";
import logo from "../images/logo.svg";
import notification from "../images/notification 2.svg";
import profile from "../images/profile.svg";
import { Link, useParams } from "react-router-dom";
import "../Header/Header.css";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import Loading from "../components/common/loading";

function Header() {
  const { id } = useParams;
  const location = useLocation();
  const { user } = useContext(AuthContext);

  return (
    <>
      <header className="header">
        <div className="container">
          <div className="row w-100">
            <div className="col-3">
              <img src={logo} alt="Lemolite Logo" className="logo" />
            </div>
            <div className="col-6">
              {user && user.role === "HR" ? (
                <nav className="nav_bar">
                  <Link
                    to="/hr/request"
                    className={
                      location.pathname === "/hr/request"
                        ? "active nav-link"
                        : "nav-link"
                    }
                  >
                    Request
                  </Link>
                  <Link
                    to="/hr/inventory"
                    className={
                      location.pathname === "/hr/inventory" ||
                      location.pathname === "/hr/inventory/inventoryadditem" ||
                      location.pathname === "/hr/inventory/assest" ||
                      location.pathname.startsWith(`/hr/inventory/inventryedit`)
                        ? "active nav-link"
                        : "nav-link"
                    }
                  >
                    Inventory
                  </Link>
                  <Link
                    to="/hr/assigniitems"
                    className={
                      location.pathname === "/hr/assigniitems" ||
                      location.pathname === "/hr/assigniitems/allocateitem" ||
                      location.pathname.startsWith(`/hr/assigniitems/edit`)
                        ? "active nav-link"
                        : "nav-link"
                    }
                  >
                    Assign Items
                  </Link>
                  <Link
                    to="/hr/assetQR"
                    className={
                      location.pathname === "/hr/assetQR" ||
                      location.pathname.startsWith(`/hr/assetqr/assestqrcode`)
                        ? "active nav-link"
                        : "nav-link"
                    }
                  >
                    Asset QR
                  </Link>
                  <div className="animation start-home"></div>
                </nav>
              ) : (
                (user && user.role === "Employee",
                "PM" && (
                  <nav className="nav_bar empnav">
                    <Link
                      to="/employee/empitem"
                      className={
                        location.pathname === "/employee/empitem"
                          ? "active nav-link"
                          : "nav-link"
                      }
                    >
                      Items
                    </Link>
                    <Link
                      to="/employee/request/"
                      className={
                        location.pathname === "/employee/request/" ||
                        location.pathname ===
                          "/employee/request/addrequestform" ||
                        location.pathname.startsWith(
                          `/employee/request/addrequestedit`
                        )
                          ? "active nav-link"
                          : "nav-link"
                      }
                    >
                      Request
                    </Link>
                  </nav>
                ))
              )}
            </div>

            <div className="col-3 p-0">
              <div className="user_notification">
                {/* <img
                  src={notification}
                  alt="notification"
                  className="notification"
                /> */}
                <img
                  src={profile}
                  alt="profile"
                  className="profile"
                  width="33px"
                />
                <h6 className="m-0 username"> {user && user.name} </h6>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
