import React from "react";
import PropTypes from "prop-types";
import closeicon from "../../../images/close-icon.svg";
import FormattedDate from "../../common/FormattedDate";

const EmpviewModel = ({ item, onClose }) => {
  return (
    <div className="modal">
      <div className="modal-content">
        {/* <div className="img_secs">
          <img src={item.qrcode_path} alt="" width="100%" height="100%"/>
        </div> */}
        <div>
          <span className="close-icon" onClick={onClose}>
            <img src={closeicon} alt="Close" />
          </span>
          <p>
            <strong>Item Name : </strong> {item.item_name}
          </p>
          <p>
            <strong>Purchased At : </strong>
            <FormattedDate date={item.purchase_date} />
            {/* {item.purchase_date} */}
            {/* {new Date(item.purchase_date).toLocaleDateString()} */}
          </p>
          <p>
            <strong>Type : </strong> {item.type_name}
          </p>
          <p className="detailed_fix">
            <strong>Device Details : </strong> {item.details}
          </p>
        </div>
      </div>
    </div>
  );
};

EmpviewModel.propTypes = {
  item: PropTypes.shape({
    item_name: PropTypes.string,
    purchase_date: PropTypes.string,
    type_name: PropTypes.string,
    details: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EmpviewModel;
