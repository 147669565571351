import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import EmpItem from "../Employees/Empitem/Empitem";
import EmpRequest from "./EmpRequest/EmpRequest";
import EmpRequestEditForm from "./EmpRequest/EmpRequestEditForm";
import EmpRequestForm from "./EmpRequest/EmpRequestForm";
import Header from "../../Header/Header";
const NavigationEmp = () => {
  return (
    <>
      {/* <Header /> */}
      <Routes>
        <Route path="/empitem" element={<EmpItem />} />
        <Route path="request" element={<EmpRequest />} />
        <Route
          path="request/addrequestedit/:id"
          element={<EmpRequestEditForm />}
        />

        <Route path="request/addrequestform" element={<EmpRequestForm />} />
      </Routes>
    </>
  );
};
export default NavigationEmp;
