import React, { useContext } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Header from "../src/Header/Header";
import Hr from "./components/Page/Hr";
import Sso from "../src/components/Page/SSo";
import NavigationHR from "./components/HR/NavigationHR";
import NavigationEmp from "./components/Employees/NavigationEmp";
import PrivateRoute from "./Hook/PrivateRoute";
import Loading from "./components/common/loading";
import { AuthContext } from "../src/context/AuthContext";

import "../src/App.css";
import "../src/css/style.css";
import "../src/css/comon.css";
import ScanPage from "./components/Page/ScanPage";

const App = () => {
  const { user } = useContext(AuthContext);

  return (
    <>
      <Routes>
        <Route path="/sso" element={<Sso />} />
        <Route path="/qrcode" element={<ScanPage />} />
      </Routes>
      {!user ? (
        <Loading />
      ) : (
        <>
          <Header />
          <Routes>
            <Route
              path="/hr/*"
              element={
                <PrivateRoute element={<NavigationHR />} roles={["HR"]} />
              }
            />
            <Route
              path="/employee/*"
              element={
                <PrivateRoute
                  element={<NavigationEmp />}
                  roles={["Employee", "PM"]}
                />
              }
            />
          </Routes>
        </>
      )}
    </>
  );
};

export default App;
