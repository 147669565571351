import React, { useState, useEffect } from "react";
import SearchbarTop from "../../common/SearchbarTop";
import view from "../../../images/view.svg";
import axios from "axios";
import Pagination from "../../common/Pagination";
import Loading from "../../common/loading";
import { useNavigate } from "react-router-dom";
import AssestQrViewScan from "./AssestQrViewScan";

const AssetQR = () => {
  const itemsPerPage = 6;
  const [currentPage, setCurrentPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [modalData, setModalData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/api/get_user_assetitems");
        const sortedData = response.data.user_assetitems.sort((a, b) => {
          return (
            new Date(b.user_assetitem.created_at) -
            new Date(a.user_assetitem.created_at)
          );
        });
        setData(sortedData);
        setFilteredData(sortedData);
      } catch (error) {
        console.error("API Error:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const lowerCaseInput = searchInput.toLowerCase();
    const newFilteredData = data.filter((item) =>
      JSON.stringify(item).toLowerCase().includes(lowerCaseInput)
    );
    setFilteredData(newFilteredData);
    setCurrentPage(1); // Reset to the first page when filtering
  }, [searchInput, data]);

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const currentItems = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleViewItemClick = (id) => {
    navigate(`/hr/assetqr/assestqrcode/${id}`);
    setSelectedItemId(id);
    setIsModalOpen(true);
  };

  return (
    <div className="section">
      <div className="container">
        <SearchbarTop
          title="Asset QR Code"
          onSearchInputChange={(input) => setSearchInput(input)}
        />

        {loading ? (
          <Loading />
        ) : (
          <>
            {currentItems.length > 0 ? (
              <div className="table-container">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Id</th>
                      <th scope="col">NAME</th>
                      <th scope="col">DEPARTMENT</th>
                      <th scope="col">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.map((item, index) => {
                      const assetItem = item.user_assetitem;
                      return (
                        <tr key={index} className="table_tr">
                          <td>
                            {(currentPage - 1) * itemsPerPage + index + 1}
                          </td>
                          <td>{assetItem.user_id.name}</td>
                          <td>{assetItem.department.name}</td>
                          <td>
                            <img
                              src={view}
                              alt="view"
                              className="view action_icon"
                              onClick={() => handleViewItemClick(assetItem._id)}
                              style={{ cursor: "pointer" }}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <h2 className="data_noavailble">Data Not Available</h2>
            )}
            <Pagination
              currentPage={currentPage}
              totalItems={filteredData.length}
              itemsPerPage={itemsPerPage}
              onPageChange={handlePageChange}
            />
            {isModalOpen && <AssestQrViewScan userId={selectedItemId} />}
          </>
        )}
      </div>
    </div>
  );
};

export default AssetQR;
