import React from "react";
import leftarrow from "../../images/leftarrowsvg.svg";
import rightarrow from "../../images/rightarrow.svg";

const Pagination = ({
  currentPage,
  totalItems,
  itemsPerPage,
  onPageChange,
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // Only show pagination if there are more than 5 items
  if (totalItems <= itemsPerPage) {
    return null;
  }

  // Generate an array of page numbers
  const pageNumbers = [...Array(totalPages).keys()].map((num) => num + 1);

  return (
    <div className="pagination">
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        <img src={leftarrow} alt="arrow" width="" height="" />
      </button>

      {pageNumbers.map((number) => (
        <button
          key={number}
          onClick={() => onPageChange(number)}
          className={number === currentPage ? "active" : ""}
        >
          {number}
        </button>
      ))}

      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        <img src={rightarrow} alt="arrow" width="" height="" />
      </button>
    </div>
  );
};

export default Pagination;
