import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import goback from "../../images/go back.svg";
import { useState } from 'react';
import searchIcon from "../../images/search-icon.svg";
import arrow from "../../images/leftarrowsvg.svg"
import { useLocation } from "react-router-dom";




const SearchbarTop = ({ title }) => {
  const navigate = useNavigate(); 
  const location = useLocation();
 
  const handleBackClick = () => {
    navigate(-1); 
  };
  return (
    <> 
     <Link to='https://hrems.lemolite.com/dashboard/time-attendance' className='d-flex align-items-center justify-content-end'>
        <img src={arrow} alt="arrow" width="" height=""/>
      <h4 className='heading4 blue-color font-600 m-0 ml-2'>        
        GO BACK TO HREMS</h4>
        </Link>
        <div className="searchbar-top">
      <div className="content-title">
        {title}
      </div>
      <div className='d-flex align-items-center cursor-pointer' onClick={handleBackClick}>
      <img src={arrow} alt="arrow" width="" height=""/>
      <h4 className='heading4 blue-color font-600 m-0 ml-2 ' >BACK</h4>
      </div>
    </div>
   
  
    </>

  );
};

export default SearchbarTop;
