import React, { useState, useEffect } from "react";
import SearchbarTop from "../../common/SearchbarTop2";
import axios from "axios";
import Pagination from "../../common/Pagination";
import Loading from "../../common/loading";
import { useNavigate } from "react-router-dom";

const InventoryAssets = () => {
  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState({ inventory: [] });
  const [loading, setLoading] = useState(true);
  const [uniqueItemNames, setUniqueItemNames] = useState([]);
  const [itemStats, setItemStats] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/api/get_asset");
        setData(response.data);
      } catch (error) {
        console.error("API Error:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (data?.inventory && data.inventory.length > 0) {
      const itemNames = [
        ...new Set(data.inventory.map((item) => item.item.item_name)),
      ];
      setUniqueItemNames(itemNames);

      const stats = itemNames.reduce((acc, itemName) => {
        const filteredItems = data.inventory.filter(
          (item) => item.item.item_name === itemName
        );
        const trueCount = filteredItems.filter(
          (item) => item.is_allocate
        ).length;
        const falseCount = filteredItems.filter(
          (item) => !item.is_allocate
        ).length;
        const damageCount = filteredItems.filter(
          (item) => item.type.type_name === "Damage"
        ).length;
        acc[itemName] = {
          allocated: trueCount,
          deallocated: falseCount,
          damaged: damageCount,
          total: trueCount + falseCount + damageCount,
        };
        return acc;
      }, {});

      setItemStats(stats);
    }
  }, [data]);

  const grandTotals = Object.values(itemStats).reduce(
    (totals, stats) => {
      totals.allocateGrandTotal += stats.allocated || 0;
      totals.deallocateGrandTotal += stats.deallocated || 0;
      totals.damageGrandTotal += stats.damaged || 0;
      totals.totalGrandTotal += stats.total || 0;
      return totals;
    },
    {
      allocateGrandTotal: 0,
      deallocateGrandTotal: 0,
      damageGrandTotal: 0,
      totalGrandTotal: 0,
    }
  );

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const totalPages = Math.ceil((uniqueItemNames.length || 0) / itemsPerPage);
  const currentItems = uniqueItemNames.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="section">
      <div className="container">
        <SearchbarTop title="Asset" />
        {loading ? (
          <Loading loading={loading} />
        ) : (
          <>
            {currentItems.length > 0 ? (
              <div className="table-container">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">NO</th>
                      <th scope="col">Item</th>
                      <th scope="col">Allocated</th>
                      <th scope="col">Deallocated</th>
                      <th scope="col">Damaged</th>
                      <th scope="col">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.map((item, index) => {
                      const stats = itemStats[item];
                      return (
                        <tr key={index} className="table_tr">
                          <td>
                            {(currentPage - 1) * itemsPerPage + index + 1}
                          </td>
                          <td>{item}</td>
                          <td>{stats?.allocated || 0}</td>
                          <td>{stats?.deallocated || 0}</td>
                          <td>{stats?.damaged || 0}</td>
                          <td>{stats?.total || 0}</td>
                        </tr>
                      );
                    })}
                    <tr></tr>
                    <tr className="table_tr">
                      <td></td>
                      <td>
                        <strong>Grand Total</strong>
                      </td>
                      <td>
                        <strong>{grandTotals.allocateGrandTotal || "-"}</strong>
                      </td>
                      <td>
                        <strong>
                          {grandTotals.deallocateGrandTotal || "-"}
                        </strong>
                      </td>
                      <td>
                        <strong> {grandTotals.damageGrandTotal || "-"}</strong>
                      </td>
                      <td>
                        <strong> {grandTotals.totalGrandTotal || "-"}</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              <h2 className="data_noavailble">Data Not Available</h2>
            )}
            <Pagination
              currentPage={currentPage}
              totalItems={uniqueItemNames.length}
              itemsPerPage={itemsPerPage}
              onPageChange={handlePageChange}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default InventoryAssets;
