import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import SearchbarTop from "../../common/SearchbarTop2";
import ThankyouModal from "../../common/ThankyouModal";
import { toast } from "react-toastify";
const EmpRequestForm = () => {
  const [items, setItems] = useState([]);
  const [DamageItems, setDamageItems] = useState([]);
  const [types, setTypes] = useState([]);
  const [priority, setPriority] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [selectedType, setSelectedType] = useState("New");

  const navigate = useNavigate();
  const initialValues = {
    priority_id: "",
    type_id: "",
    asset_inventory_id: "",
    departments_id: "",
    details: "",
    item_id: "",
    images: [],
  };
  useEffect(() => {
    const fetchTypesAndPriority = async () => {
      try {
        const [typesResponse, priorityResponse] = await Promise.all([
          axios.get("/api/records/types"),
          axios.get("/api/records/priority"),
        ]);
        setTypes(typesResponse.data.records || []);
        setPriority(priorityResponse.data.records || []);
      } catch (error) {
        setError(error.message || "Error fetching data");
        // console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchTypesAndPriority();
  }, []);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        let itemsResponse;
        if (selectedType === "New") {
          itemsResponse = await axios.get("/api/records/items");
          setItems(
            itemsResponse.data.records.map((it) => ({
              item_name: it?.name,
              asset_inventory_id: it?._id,
            })) || []
          );
        } else if (selectedType === "Damage") {
          itemsResponse = await axios.get("/api/get_my_allocated_assetitems");

          setDamageItems(itemsResponse.data.user_assetitems || []);
        }
      } catch (error) {
        setError(error.message || "Error fetching items");
        // console.error("Error fetching items:", error);
      }
    };
    if (selectedType) {
      fetchItems();
    }
  }, [selectedType]);
  const validationSchema = Yup.object({
    type_id: Yup.string().required("Required"),
    priority_id: Yup.string().required("Required"),
    details: Yup.string().required("Required"),
  });
  const onSubmit = async (values, { setSubmitting }) => {
    if (values.images.length > 3) {
      toast.error("You can Upload maximum 3 images");
      return;
    }
    try {
      const formData = new FormData();
      for (const key in values) {
        if (key === "images") {
          for (let i = 0; i < Math.min(values.images.length, 3); i++) {
            formData.append("images", values.images[i]);
          }
        } else {
          formData.append(key, values[key]);
        }
      }
      for (let [key, value] of formData.entries()) {
        console.log(`${key}:`, value);
      }
      const response = await axios.post("/api/user_request", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setIsSubmitted(true);
    } catch (error) {
      if (error.response?.status === 413) {
        toast.error("The file size is too large");
      } else {
        setError(
          error.response?.data?.message ||
            error.message ||
            "Error submitting data"
        );
      }
    } finally {
      setSubmitting(false);
    }
  };

  const handleClosePopup = () => {
    setIsSubmitted(false);
    navigate(-1);
  };
  return (
    <div className="edit-item-form">
      <div className="container">
        <SearchbarTop title="Add Request" />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form className="form editform">
              <div className="row">
                <div className="col-6">
                  <fieldset>
                    <legend>Type</legend>
                    <Field
                      as="select"
                      name="type_id"
                      onChange={(e) => {
                        const selectedValue = e.target.value;
                        setFieldValue("type_id", selectedValue);
                        setSelectedType(
                          types.find((type) => type._id === selectedValue)
                            ?.name || ""
                        );
                      }}
                    >
                      <option value="" label="Select Type" />
                      {types.length ? (
                        types.map((type) => (
                          <option key={type._id} value={type._id}>
                            {type.name}
                          </option>
                        ))
                      ) : (
                        <option value="">Loading...</option>
                      )}
                    </Field>
                    <ErrorMessage
                      name="type_id"
                      component="div"
                      className="error-message"
                    />
                  </fieldset>
                </div>
                <div className="col-6">
                  <fieldset>
                    <legend>Items</legend>
                    {selectedType === "New" ? (
                      <Field
                        as="select"
                        name="item_id"
                        onChange={(e) => {
                          const selectedValue = e.target.value;
                          setFieldValue("item_id", selectedValue);
                        }}
                      >
                        <option value="" label="Select Item" />
                        {items.length ? (
                          items.map((item) => (
                            <option
                              key={item?.asset_inventory_id}
                              value={item?.asset_inventory_id}
                            >
                              {item.item_name}
                            </option>
                          ))
                        ) : (
                          <option value="">No items available</option>
                        )}
                      </Field>
                    ) : selectedType === "Damage" ? (
                      <Field
                        as="select"
                        name="asset_inventory_id"
                        onChange={(e) => {
                          const selectedValue = e.target.value;
                          const selectedItem = DamageItems.find(
                            (item) =>
                              item?.asset_inventory[0].asset_inventory_id ===
                              selectedValue
                          );

                          const itemId = selectedItem?.item_id; // Assuming item_id is in the selected item

                          // Set both asset_inventory_id and item_id
                          setFieldValue("asset_inventory_id", selectedValue);
                          setFieldValue("item_id", itemId);
                        }}
                      >
                        <option value="" label="Select Item" />
                        {DamageItems.length ? (
                          DamageItems.map((item) => (
                            <option
                              key={item?.asset_inventory[0].asset_inventory_id}
                              value={
                                item?.asset_inventory[0].asset_inventory_id
                              }
                            >
                              {item.asset_inventory[0].item_name}
                            </option>
                          ))
                        ) : (
                          <option value="">No items available</option>
                        )}
                      </Field>
                    ) : null}
                    <ErrorMessage
                      name={
                        selectedType === "New"
                          ? "item_id"
                          : "asset_inventory_id"
                      }
                      component="div"
                      className="error-message"
                    />
                  </fieldset>
                </div>
                <div className="col-6">
                  <fieldset>
                    <legend>Priority</legend>
                    <Field
                      as="select"
                      name="priority_id"
                      onChange={(e) => {
                        const selectedValue = e.target.value;
                        setFieldValue("priority_id", selectedValue);
                      }}
                    >
                      <option value="" label="Select Priority" />
                      {priority.length ? (
                        priority.map((item) => (
                          <option key={item._id} value={item._id}>
                            {item.name}
                          </option>
                        ))
                      ) : (
                        <option value="">No priorities available</option>
                      )}
                    </Field>
                    <ErrorMessage
                      name="priority_id"
                      component="div"
                      className="error-message"
                    />
                  </fieldset>
                </div>
                <div className="col-6">
                  <fieldset>
                    <legend>File Upload</legend>
                    <input
                      type="file"
                      name="images"
                      multiple
                      onChange={(event) => {
                        const files = Array.from(event.currentTarget.files);
                        setFieldValue("images", files);
                      }}
                    />
                    <ErrorMessage
                      name="file"
                      component="div"
                      className="error-message"
                    />
                  </fieldset>
                </div>
                <div className="col-12">
                  <fieldset>
                    <legend>Details</legend>
                    <Field as="textarea" name="details" rows="7" />
                    <ErrorMessage
                      name="details"
                      component="div"
                      className="error-message"
                    />
                  </fieldset>
                </div>
              </div>

              <button
                type="submit"
                disabled={isSubmitting}
                className="btn"
                style={{
                  margin: "0 auto",
                  display: "block",
                }}
              >
                SUBMIT
              </button>
            </Form>
          )}
        </Formik>
        {isSubmitted && <ThankyouModal handleok={handleClosePopup} />}
      </div>
    </div>
  );
};
export default EmpRequestForm;
