import React from "react";
import img from "../../images/thanksimgsvg.svg";

const ThankyouModal = ({ handleok }) => {
  const handleDelete = () => {
    handleok();
  };

  return (
    <div className="modal delete_model">
      <div className="modal-content">
        <img src={img} alt="Delete" className="delete-image" />
        <h2>Thanks</h2>
        <p>Your response has been Updated!</p>
        <div className="buttons d-flex gap-3 " style={{ gap: "20px" }}>
          <button onClick={handleok} className="btn2">
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default ThankyouModal;
