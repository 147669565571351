import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import SearchbarTop from "../../common/SearchbarTop2";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import ThankyouModal from "../../common/ThankyouModal";
import Loading from "../../common/loading";

const validationSchema = Yup.object({
  employee: Yup.string().required("Employee is required"),
  department: Yup.string().required("Department is required"),
  item_id: Yup.array()
    .of(Yup.string())
    .required("At least one item must be selected"),
});
const AllocateItemsForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedValues, setSelectedValues] = useState({});
  const [selectedDetails, setSelectedDetails] = useState([]);
  const [initialValues, setInitialValues] = useState({
    item_id: [],
    employee: "",
    department: "",
  });
  const [uniqueCategories, setUniqueCategories] = useState([]);
  const [uniqueSubData, setUniqueSubData] = useState([]);
  useEffect(() => {
    const items_uniq = items.map((item) => ({
      id: item._id,
      item_name: item.item.item_name,
    }));
    const uniqueMap = new Map();
    items_uniq.forEach((item) => {
      if (!uniqueMap.has(item.item_name)) {
        uniqueMap.set(item.item_name, item);
      }
    });
    const unique = Array.from(uniqueMap.values());
    setUniqueCategories(unique);
  }, [items]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [employeesResponse, itemsResponse, departmentsResponse] =
          await Promise.all([
            axios.get("/api/employees/list"),
            axios.get("/api/get_all_asset"),
            axios.get("/api/records/departments"),
          ]);
        const sortedEmployees = (employeesResponse.data.employees || []).sort(
          (a, b) => a.name.localeCompare(b.name)
        );
        setEmployees(sortedEmployees);
        setItems(itemsResponse.data.inventory || []);
        setDepartments(departmentsResponse.data.records || []);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  const inventory_ids = Object.values(selectedValues).map((item) => item.id);
  const onSubmit = async (values, { setSubmitting }) => {
    const dataToPost = {
      user_id: values.employee,
      asset_inventory_ids: inventory_ids, // Use selectedValues state here
      departments_id: values.department,
    };

    try {
      const response = await axios.post("/api/allocate_assetitems", dataToPost);
      setIsSubmitted(true);
    } catch (error) {
      console.error("Error posting data:", error);
    } finally {
      setSubmitting(false);
    }
  };
  const handleClosePopup = () => {
    setIsSubmitted(false);
    navigate(-1);
  };
  const handleSelectChange = (e, setFieldValue) => {
    const selectedOptions = Array.from(e.target.selectedOptions).map(
      (option) => option.value
    );
    const selectedItems = items
      .filter((item) => selectedOptions.includes(item.item.item_name))
      .map((item) => item._id);
    setUniqueSubData(
      selectedItems.map((id) => items.find((item) => item._id === id))
    );
    setFieldValue("item_id", selectedItems);
  };
  const handleRadioChange = (event, details, groupName) => {
    const value = event.target.value;
    // Assuming you want to update the state to include both the id and the existing details
    setSelectedValues((prevSelectedDetails) => ({
      ...prevSelectedDetails,
      [groupName]: {
        id: value,
        details: details,
      },
    }));
  };
  const handlekeyDelete = (id) => {
    setSelectedValues((prevSelectedValues) => {
      const updatedValues = Object.fromEntries(
        Object.entries(prevSelectedValues).filter(
          ([key, value]) => value.id !== id
        )
      );
      return updatedValues;
    });
  };
  return (
    <div className="edit-item-form">
      <div className="container">
        <SearchbarTop title="Allocate Items" />
        {loading ? (
          <Loading />
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, setFieldValue, values }) => (
              <div className="form editform">
                <Form>
                  <div className="row">
                    <div className="col-12">
                      <fieldset>
                        <legend>Employee</legend>
                        <Field as="select" name="employee">
                          <option value="" label="Select Employee" />
                          {employees.map((employee) => (
                            <option key={employee.id} value={employee.id}>
                              {employee.name}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="employee"
                          component="div"
                          className="error-message"
                        />
                      </fieldset>
                    </div>
                    <div className="col-6">
                      <fieldset>
                        <legend>Department</legend>
                        <Field as="select" name="department">
                          <option value="" label="Select Department" />
                          {departments.map((department) => (
                            <option key={department._id} value={department._id}>
                              {department.name}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="department"
                          component="div"
                          className="error-message"
                        />
                      </fieldset>
                    </div>
                    <div className="col-6">
                      <fieldset>
                        <legend>Items</legend>
                        <Field
                          as="select"
                          name="item_id"
                          onChange={(e) => handleSelectChange(e, setFieldValue)}
                        >
                          <option value="" label="Select Item" />
                          {uniqueCategories.map((item) => (
                            <option key={item.id} value={item.item_name}>
                              {item.item_name}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="item_id"
                          component="div"
                          className="error-message"
                        />
                      </fieldset>
                      <div className="selected-items">
                        {Object.entries(selectedValues).map(
                          ([groupName, detailsObj]) => (
                            <div key={groupName} className="selected-item ">
                              <span>{groupName}</span>
                              <span
                                onClick={() => handlekeyDelete(detailsObj.id)}
                                style={{
                                  cursor: "pointer",
                                  color: "red",
                                  marginLeft: "5px",
                                }} // Optional styling
                              >
                                &times;
                              </span>
                            </div>
                          )
                        )}
                      </div>
                      {/* {Object.entries(selectedValues).map(
                        ([groupName, detailsObj]) => (
                          <div key={groupName}>
                            <strong>{groupName}</strong>: {detailsObj.details}{" "}
                            <span
                              onClick={() => handlekeyDelete(detailsObj.id)}
                              style={{ cursor: "pointer", color: "red" }} // Optional styling
                            >
                              delete
                            </span>
                          </div>
                        )
                      )} */}
                    </div>
                    <div className="col-12">
                      {uniqueSubData.length > 0 && (
                        <>
                          <legend className="mb-2">Item Details</legend>
                          <div className="d-flex allocate_item_details">
                            {uniqueSubData.map((item) => (
                              <div key={item._id} className="item">
                                <Field
                                  type="radio"
                                  name={item.item.item_name}
                                  checked={
                                    selectedValues[item.item.item_name]?.id ===
                                    item._id
                                  }
                                  value={item._id}
                                  onChange={(event) =>
                                    handleRadioChange(
                                      event,
                                      item.details,
                                      item.item.item_name
                                    )
                                  }
                                />
                                <h6 className="m-0">{item.details}</h6>
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn"
                    style={{ margin: "0 auto", display: "block" }}
                  >
                    {isSubmitting ? "SUBMITING..." : "SUBMIT"}
                  </button>
                </Form>
              </div>
            )}
          </Formik>
        )}

        {isSubmitted && <ThankyouModal handleok={handleClosePopup} />}
      </div>
    </div>
  );
};
export default AllocateItemsForm;
