import React from "react";
import deleteImage from "../../images/deleteImage.svg";

const ConfirmModel = ({ data, onUpdate, onClose }) => {
  const handleupdate = () => {
    onUpdate(data);
    onClose();
  };

  return (
    <div className="modal delete_model">
      <div className="modal-content">
        <img src={deleteImage} alt="Delete" className="delete-image" />
        <h2> Are You Sure?</h2>
        <p className="">
          Do you really want to update this record? This process cannot be
          undone.
        </p>
        <div className="buttons d-flex gap-3" style={{ gap: "20px" }}>
          <button onClick={handleupdate} className="btn2">
            Update
          </button>
          <button onClick={onClose} className="btn2">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModel;
