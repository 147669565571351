import React, { useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";

const Sso = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { checkuth } = useContext(AuthContext);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");
    // console.log("token", token);

    if (token) {
      checkuth(token);
    }
  }, [location.search, checkuth, navigate]);

  return <div>SSO Page </div>;
};

export default Sso;
