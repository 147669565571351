import React, { useState } from "react";
import ConfirmModel from "../../common/ConfirmModel";

const StatusDropdown = ({ status, onStatusChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(status.trim());
  const [isupdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const [pendingStatus, setPendingStatus] = useState(null);

  const statuses = [
    "In Progress",
    "Hold",
    "Reject",
    "In Active",
    "Pending",
    "Complete",
  ];

  const handleStatusChange = (newStatus) => {
    setPendingStatus(newStatus.trim());
    setIsUpdateModalVisible(true);
  };

  const confirmStatusChange = () => {
    if (pendingStatus) {
      setSelectedStatus(pendingStatus);
      onStatusChange?.(pendingStatus);
      setPendingStatus(null);
    }
    setIsUpdateModalVisible(false);
    setIsOpen(false);
  };

  return (
    <div className="dropdown-container">
      <div
        onClick={() => setIsOpen(!isOpen)}
        className={`btn_status ${selectedStatus.toLowerCase()} ${
          isOpen ? "open" : ""
        }`}
      >
        {selectedStatus}
        <span className="icon">⮟</span>
      </div>

      {isOpen && (
        <div className="dropdown-content">
          {statuses.map((status) => (
            <div
              key={status}
              className="dropdown-item"
              onClick={() => handleStatusChange(status)}
            >
              {status}
            </div>
          ))}
        </div>
      )}

      {isupdateModalVisible && (
        <ConfirmModel
          data={{ status: pendingStatus }}
          onUpdate={confirmStatusChange}
          onClose={() => setIsUpdateModalVisible(false)}
        />
      )}
    </div>
  );
};

export default StatusDropdown;
