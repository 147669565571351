import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import SearchbarTop from "../../common/SearchbarTop2";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import ThankyouModal from "../../common/ThankyouModal";
import Loading from "../../common/loading";
import moment from "moment";
const validationSchema = Yup.object({
  item_id: Yup.string(),
  type_id: Yup.string(),
  purchase_date: Yup.string(),
  details: Yup.string(),
});

const InventoryEditItemForm = () => {
  const { id } = useParams();
  const [items, setItems] = useState([]);
  const [types, setTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const today = new Date().toISOString().split("T")[0];
  const [initialValues, setInitialValues] = useState({
    item_id: "",
    type_id: "",
    purchase_date: "",
    details: "",
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [itemsResponse, typesResponse] = await Promise.all([
          axios.get("/api/records/items"),
          axios.get("/api/records/types"),
        ]);
        setItems(itemsResponse.data.records || []);
        setTypes(typesResponse.data.records || []);
      } catch (error) {
        setError(error);
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    const fetchAssetData = async () => {
      try {
        const response = await axios.get(`/api/get_asset_by_id/${id}`);
        // console.log("response....", response);

        const asset = response.data.asset || {
          item_id: "",
          type_id: "",
          purchase_date: "",
          details: "",
        };
        setInitialValues({
          item_name: asset.item.item_name,
          type_id: asset.type_id,
          type_name: asset.type.type_name,
          purchase_date: asset.purchase_date,

          details: asset.details,
        });
      } catch (error) {
        // console.error("Error fetching asset data:", error);
        toast.error("Error fetching asset data.");
      }
    };
    fetchAssetData();
  }, [id]);
  const onSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await axios.put(`/api/update_asset/${id}`, values, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      toast.success(response.message);
      setIsSubmitted(true);
    } catch (error) {
      toast.error(error.response ? error.response.data : error.message);
    } finally {
      setSubmitting(false);
    }
  };
  const handleClosePopup = () => {
    setIsSubmitted(false);
    navigate(-1);
  };
  return (
    <div className="edit-item-form">
      <div className="container">
        <SearchbarTop title="Edit Inventory" />
        {loading ? (
          <Loading />
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize={true}
          >
            {({ isSubmitting, values }) => (
              <div className="form editform">
                <Form>
                  <div className="row">
                    <div className="col-12">
                      <fieldset>
                        <legend>Items</legend>
                        <Field as="select" name="item_id">
                          <option value="" label={initialValues.item_name}>
                            Select item
                          </option>
                          {items.map((item) => (
                            <option key={item._id} value={item._id}>
                              {item.name}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="item_id"
                          component="div"
                          className="error-message"
                        />
                      </fieldset>
                    </div>
                    <div className="col-6">
                      <fieldset>
                        <legend>Type</legend>
                        <Field as="select" name="type_id">
                          <option value="" label={initialValues.type_name}>
                            Select type
                          </option>
                          {types.map((type) => (
                            <option key={type._id} value={type._id}>
                              {type.name}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="type_id"
                          component="div"
                          className="error-message"
                        />
                      </fieldset>
                    </div>
                    <div className="col-6">
                      <fieldset>
                        <legend>Purchase Date</legend>
                        <Field
                          name="purchase_date"
                          type="date"
                          lable={initialValues.purchase_date}
                          className="input-field"
                          max={today}
                        />
                        <ErrorMessage
                          name="purchase_date"
                          component="div"
                          className="error-message"
                        />
                      </fieldset>
                    </div>
                    <div className="col-12">
                      <fieldset>
                        <legend>Items Details</legend>
                        <Field as="textarea" name="details" rows="7" />
                        <ErrorMessage name="details" component="div" />
                      </fieldset>
                    </div>
                  </div>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn"
                    style={{ display: "block", margin: "0 auto" }}
                  >
                    {isSubmitting ? "Updating..." : "UPDATE"}
                  </button>
                </Form>
              </div>
            )}
          </Formik>
        )}
        {isSubmitted && <ThankyouModal handleok={handleClosePopup} />}
      </div>
    </div>
  );
};
export default InventoryEditItemForm;
