import React from "react";
import PropTypes from "prop-types";
import closeicon from "../../images/closeblack.png";

const ScanImgModel = ({ item, onClose }) => {
  // console.log("item", item);

  return (
    <div className="modal scanimg_model">
      <div className="modal-content p-0">
        <span className="close-icon" onClick={onClose}>
          <img src={closeicon} alt="New" className="" width="100%" />
        </span>
        <img
          src={`/${item.qrcode_path}`}
          className="rounded"
          alt="QR Code"
          width="100%"
          height="100%"
        />
      </div>
    </div>
  );
};

ScanImgModel.propTypes = {
  item: PropTypes.shape({
    qrcode_path: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ScanImgModel;
