import React, { useState, useEffect } from "react";
import SearchbarTop from "../../common/SearchbarTop";
import view from "../../../images/view.svg";
import axios from "axios";
import Pagination from "../../common/Pagination";
import { useNavigate } from "react-router-dom";
import newicon from "../../../images/newicon.svg";
import damageicon from "../../../images/damageicon.svg";
import Loading from "../../common/loading";
import RequestItemViewModel from "../Request/RequestItemViewModel";
import { toast } from "react-toastify";
import RequestStatus from "../Request/RequestStatus";

const Request = () => {
  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [modalData, setModalData] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [statusData, setStatusData] = useState({});
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  console.log("data..", data);

  // get all request
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/api/get_all_requests");
        const fetchedData = response.data.user_assetitems_requests;

        const sortedData = fetchedData.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });
        setData(sortedData);
        setFilteredData(sortedData);
        setTotalItems(sortedData.length);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  const handleSearchInputChange = (searchInput) => {
    const lowerCaseInput = searchInput.toLowerCase();
    const filterData = (item) =>
      JSON.stringify(item).toLowerCase().includes(lowerCaseInput);
    const newFilteredData = data.filter(filterData);
    setFilteredData(newFilteredData);
    setCurrentPage(1);
    setTotalItems(newFilteredData.length);
  };

  const handleViewItemClick = (itemId) => {
    const selectedItem = data.find((item) => item._id === itemId);
    setModalData(selectedItem);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalData(null);
  };

  // pagination
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleDropdownToggle = (itemId) => {
    setDropdownOpen(dropdownOpen === itemId ? null : itemId);
  };
  const handleStatusChange = async (itemId, newStatus) => {
    try {
      const response = await axios.put(
        `/api/update_user_request_status/${itemId}`,
        { status: newStatus }
      );

      if (response.status === 200) {
        setData((prevData) =>
          prevData.map((item) =>
            item._id === itemId
              ? { ...item, status: { ...item.status, status: newStatus } }
              : item
          )
        );
        setFilteredData((prevData) =>
          prevData.map((item) =>
            item._id === itemId
              ? { ...item, status: { ...item.status, status: newStatus } }
              : item
          )
        );
        setDropdownOpen(null); // Close the dropdown after status change
      }
    } catch (error) {
      console.error("Failed to update status:", error);
    }
  };
  return (
    <>
      <div className="section">
        <div className="container">
          <SearchbarTop
            title="Employee Request"
            onSearchInputChange={handleSearchInputChange}
          />

          {loading ? (
            <Loading loading={loading} />
          ) : (
            <>
              {paginatedData.length > 0 ? (
                <div className="table-container">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">ID</th>
                        <th scope="col">NAME</th>
                        {/* <th scope="col">DEPARTMENT</th> */}
                        <th scope="col">ITEMS</th>
                        <th scope="col">PRIORITY</th>
                        <th scope="col">STATUS</th>
                        <th scope="col">TYPE</th>
                        <th scope="col">ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedData.map((item, index) => (
                        <tr key={item._id} className="table_tr">
                          <td>
                            {(currentPage - 1) * itemsPerPage + index + 1}
                          </td>
                          <td>{item.user.name}</td>
                          {/* <td>
                          {item.department?.name ? item.department.name : "-"}
                        </td> */}

                          <td>
                            {item.type.name === "Damage"
                              ? item.asset_inventory?.item_name || (
                                  <strong> Item is not Available </strong>
                                )
                              : item.item?.item_name || "-"}
                          </td>
                          <td>
                            {item.priority.name === "Low" ? (
                              <>
                                <span className="priority_dot low"></span>
                                <span className="priority_btn low"> Low </span>
                              </>
                            ) : item.priority.name === "Medium" ? (
                              <>
                                <span className="priority_dot mediam"></span>
                                <span className="priority_btn medium">
                                  Medium
                                </span>
                              </>
                            ) : item.priority.name === "High" ? (
                              <>
                                <span className="priority_dot high"></span>
                                <span className="priority_btn high">
                                  {" "}
                                  High{" "}
                                </span>
                              </>
                            ) : (
                              "-"
                            )}
                          </td>
                          {/* <td>
                            <div className="dropdown-container">
                              <div
                                className={`btn_status ${
                                  dropdownOpen === item._id ? "open" : ""
                                } ${
                                  statusData[item._id]?.toLowerCase() ||
                                  item.status.status.toLowerCase()
                                }`}
                                onClick={() => handleDropdownToggle(item._id)}
                              >
                                {statusData[item._id] || item.status.status}
                                <span className="icon">⮟</span>
                                {dropdownOpen === item._id && (
                                  <div className="dropdown-content">
                                    {[
                                      "In Progress",
                                      "Hold",
                                      "Reject",
                                      "In Active",
                                      "Pending",
                                      "Complete",
                                    ].map((status) => (
                                      <div
                                        key={status}
                                        className="dropdown-item"
                                        onClick={() =>
                                          handleStatusChange(item._id, status)
                                        }
                                      >
                                        {status}
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                            </div>
                          </td> */}
                          <td>
                            <RequestStatus
                              itemId={item._id} // Pass the item ID here
                              status={item.status.status}
                              onStatusChange={(newStatus) =>
                                handleStatusChange(item._id, newStatus)
                              }
                            />
                          </td>
                          <td>
                            {item.type.name === "New" ? (
                              <div className="btn_type new_button">
                                <img src={newicon} alt="New" className="icon" />
                                <h6> New</h6>
                              </div>
                            ) : item.type.name === "Damage" ? (
                              <div className="btn_type damage_button">
                                <img
                                  src={damageicon}
                                  alt="New"
                                  className="icon"
                                />
                                <h6> Damaged</h6>
                              </div>
                            ) : (
                              "-"
                            )}
                          </td>
                          <td>
                            <img
                              src={view}
                              alt="view"
                              className="view action_icon"
                              onClick={() => handleViewItemClick(item._id)}
                              style={{ cursor: "pointer" }}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <h2 className="data_noavailble">Data Not Available</h2>
              )}
              <Pagination
                currentPage={currentPage}
                totalItems={totalItems}
                itemsPerPage={itemsPerPage}
                onPageChange={handlePageChange}
              />
              {isModalOpen && modalData && (
                <RequestItemViewModel
                  item={modalData}
                  onClose={handleCloseModal}
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default Request;
