import React, { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import Loading from "../components/common/loading";

const PrivateRoute = ({ element, roles }) => {
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user !== undefined) {
      setLoading(false);
    }
  }, [user]);

  if (loading) {
    return <Loading />;
  }

  if (!user) {
    window.location.href =
      "https://hrems.lemolite.com/dashboard/time-attendance";
    return null;
  }

  if (roles.length > 0 && !roles.includes(user.role)) {
    if (user.role === "HR") {
      return <Navigate to="/hr/request" />;
    } else if ((user.role === "Employee", "PM")) {
      return <Navigate to="/employee/empitem" />;
    } else {
      window.location.href =
        "https://hrems.lemolite.com/dashboard/time-attendance";
      return null;
    }
  }

  return element;
};

export default PrivateRoute;
