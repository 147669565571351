import React from "react";
import moment from "moment";

const FormattedDate = ({ date }) => {
  const formatDate = (date) => {
    // Check if the date is in YYYY-MM-DD format
    if (moment(date, "YYYY-MM-DD", true).isValid()) {
      return moment(date, "YYYY-MM-DD").format("DD-MM-YYYY");
    }
    // Check if the date is in DD-MM-YYYY format
    if (moment(date, "DD-MM-YYYY", true).isValid()) {
      return moment(date, "DD-MM-YYYY").format("DD-MM-YYYY");
    }
    // Handle invalid or other formats
    return "Invalid Date Format";
  };

  return <span>{formatDate(date)}</span>;
};

export default FormattedDate;
