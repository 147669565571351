import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import SearchbarTop from "../../common/SearchbarTop2";
import { toast } from "react-toastify";
import ThankyouModal from "../../common/ThankyouModal";
import moment from "moment";

// Validation schema
const validationSchema = Yup.object({
  item_id: Yup.string().required("Required"),
  type_id: Yup.string().required("Required"),
  purchase_date: Yup.date()
    .required("Required")
    .test(
      "is-not-future-date",
      "Purchase date cannot be in the future",
      (value) => {
        return value ? moment(value).isSameOrBefore(new Date(), "day") : true;
      }
    ),
  details: Yup.string().required("Required"),
});

const InventoryAddItemsForm = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [items, setItems] = useState([]);
  const [types, setTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const navigate = useNavigate();
  const today = new Date().toISOString().split("T")[0];

  // Fetch items and types
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [itemsResponse, typesResponse] = await Promise.all([
          axios.get("/api/records/items"),
          axios.get("/api/records/types"),
        ]);
        setItems(itemsResponse.data.records || []);
        setTypes(typesResponse.data.records || []);
      } catch (error) {
        setError(error);
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const headers = {
    "Content-Type": "application/json",
    withCredentials: "true",
  };

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      // Format the purchase_date to dd-mm-yyyy
      const formattedValues = {
        ...values,
        purchase_date: values.purchase_date,
      };

      const response = await axios.post("/add_asset", formattedValues, {
        headers: headers,
        withCredentials: true,
      });
      setIsSubmitted(true); // Set isSubmitted to true upon successful submission
    } catch (error) {
      console.error(
        "Error submitting data:",
        error.response ? error.response.data : error.message
      );
    } finally {
      setSubmitting(false);
    }
  };

  const handleClosePopup = () => {
    toast.success("Form submitted successfully!");
    setIsSubmitted(false); // Close the modal after submission
    navigate(-1);
  };

  const handlePopupConfirm = () => {
    navigate("/hr/inventory");
  };

  return (
    <div className="edit-item-form">
      <div className="container">
        <SearchbarTop title="Add inventory" />
        <Formik
          initialValues={{
            item_id: "",
            type_id: "",
            purchase_date: "",
            details: "",
            is_allocate: false,
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <div className="form editform">
              <Form>
                <div className="row">
                  <div className="col-12">
                    <fieldset>
                      <legend>Items</legend>
                      <Field as="select" name="item_id">
                        <option value="" label="Select Item" />
                        {items.length ? (
                          items.map((item) => (
                            <option key={item._id} value={item._id}>
                              {item.name}
                            </option>
                          ))
                        ) : (
                          <option value="">No items available</option>
                        )}
                      </Field>
                      <ErrorMessage
                        name="item_id"
                        component="div"
                        className="error-message"
                      />
                    </fieldset>
                  </div>
                  <div className="col-6">
                    <fieldset>
                      <legend>Type</legend>
                      <Field as="select" name="type_id">
                        <option value="" label="Select Type" />
                        {types.length ? (
                          types.map((type) => (
                            <option key={type._id} value={type._id}>
                              {type.name}
                            </option>
                          ))
                        ) : (
                          <option value="">Loading...</option>
                        )}
                      </Field>
                      <ErrorMessage
                        name="type_id"
                        component="div"
                        className="error-message"
                      />
                    </fieldset>
                  </div>
                  <div className="col-6">
                    <fieldset>
                      <legend>Purchase Date</legend>
                      <Field
                        name="purchase_date"
                        type="date"
                        className="input-field"
                        placeholder="DD-MM-YY"
                        max={today}
                      />
                      <ErrorMessage
                        name="purchase_date"
                        component="div"
                        className="error-message"
                      />
                    </fieldset>
                  </div>
                  <div className="col-12">
                    <fieldset>
                      <legend>Item Details</legend>
                      <Field as="textarea" name="details" rows="7" />
                      <Field as="hidden" name="is_allocate" value="false" />
                      <ErrorMessage
                        name="details"
                        component="div"
                        className="error-message"
                      />
                    </fieldset>
                  </div>
                </div>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="btn"
                  style={{ margin: "0 auto", display: "block" }}
                >
                  {isSubmitting ? "SUBMITING..." : "SUBMIT"}
                </button>
              </Form>
            </div>
          )}
        </Formik>
        {isSubmitted && <ThankyouModal handleok={handleClosePopup} />}
      </div>
    </div>
  );
};

export default InventoryAddItemsForm;
