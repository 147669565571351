import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import SearchbarTop from "../../common/SearchbarTop2";
import ThankyouModal from "../../common/ThankyouModal";
import Loading from "../../common/loading";
import { toast } from "react-toastify";

const EmpRequestEditForm = () => {
  const { id } = useParams();
  const [items, setItems] = useState([]);
  const [types, setTypes] = useState([]);
  const [DamageItems, setDamageItems] = useState([]);
  const [selectedType, setSelectedType] = useState();
  const [imagePreview, setImagePreview] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [Priority, setPriority] = useState(null);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({
    item_id: "",
    type_id: "",
    priority_id: "",
    images: [],
    details: "",
  });
  // console.log(initialValues, "data");

  useEffect(() => {
    // Fetch items and types data from API
    const fetchItemsAndTypes = async () => {
      try {
        const typesResponse = await axios.get("/api/records/types");
        const priorityResponse = await axios.get("/api/records/priority");
        setTypes(typesResponse.data.records || []);
        setPriority(priorityResponse.data.records || []);
      } catch (error) {
        console.error("Error fetching items and types:", error);
      }
    };
    const fetchpriority = async () => {
      try {
        const priorityResponse = await axios.get("/api/records/priority");
        setPriority(priorityResponse.data.records || []);
      } catch (error) {
        console.error("Error fetching items and types:", error);
      }
    };
    // Fetch the current data of the item based on the id
    const fetchItemData = async () => {
      try {
        const response = await axios.get(
          `/api/get_user_assetitems_request/${id}`
        );
        const data = response.data;
        // console.log(data, "data");
        setSelectedType(data.user_assetitems_request.type?.name || "");
        setInitialValues({
          item_id: data.user_assetitems_request.item?.item_id || "",
          type_id: data.user_assetitems_request.type?.id || "",
          priority_id: data.user_assetitems_request.priority?.id || "",
          images: data.user_assetitems_request.images || "",
          details: data.user_assetitems_request.details || "",
          asset_inventory_id:
            data.user_assetitems_request.asset_inventory.id || "",
        });
        if (data.user_assetitems_request.images) {
          let imagePath = data.user_assetitems_request.images;
          // console.log(imagePath[0]);
          let updatedPath = imagePath[0].replace("uploads/", "");
          setImagePreview(updatedPath);
        }
      } catch (error) {
        console.error("Error fetching item data:", error);
      }
    };
    fetchItemsAndTypes();
    fetchItemData();
    fetchpriority();
  }, [id]);
  useEffect(() => {
    const fetchItems = async () => {
      try {
        let itemsResponse;
        if (selectedType === "New") {
          itemsResponse = await axios.get("/api/records/items");
          setItems(itemsResponse.data.records);
        } else if (selectedType === "Damage") {
          itemsResponse = await axios.get("/api/get_my_allocated_assetitems");
          setDamageItems(
            itemsResponse.data.user_assetitems[0]?.asset_inventory || []
          );
        }
      } catch (error) {
        console.error("Error fetching items:", error);
      }
    };
    if (selectedType) {
      fetchItems();
    }
  }, [selectedType]);
  console.log("DamageItems", DamageItems);

  const validationSchema = Yup.object({
    item_id: Yup.string().required("Required"),
    type_id: Yup.string().required("Required"),
    purchase_date: Yup.string().required("Required"),
    details: Yup.string().required("Required"),
  });
  const onSubmit = async (values, { setSubmitting }) => {
    if (values.images.length > 3) {
      toast.error("You can Upload maximum 3 images");
      return;
    }
    try {
      const formData = new FormData();
      for (const key in values) {
        if (key === "images") {
          for (let i = 0; i < Math.min(values.images.length, 3); i++) {
            formData.append("images", values.images[i]);
          }
        } else {
          formData.append(key, values[key]);
        }
      }
      for (let [key, value] of formData.entries()) {
        console.log(`${key}:`, value);
      }
      const response = await axios.put(
        `/api/update_user_request/${id}`,
        formData
      );
      setIsSubmitted(true);
    } catch (error) {
      if (error.response && error.response.status === 413) {
        toast.warning(
          "Your image size is too large. Please select smaller images."
        );
      } else {
        console.error(
          "Error updating request:",
          error.response?.data || error.message
        );
      }
    } finally {
      setSubmitting(false);
    }
  };
  const handleClosePopup = () => {
    setIsSubmitted(false);
    navigate(-1);
  };
  return (
    <div className="edit-item-form">
      <div className="container">
        <SearchbarTop title="Edit Request" />
        {loading ? (
          <Loading />
        ) : (
          <Formik
            initialValues={initialValues}
            // validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ isSubmitting, setFieldValue }) => (
              <Form className="form editform">
                <div className="row">
                  <div className="col-6">
                    <fieldset>
                      <legend>Type</legend>
                      <Field
                        as="select"
                        name="type_id"
                        onChange={(e) => {
                          const selectedValue = e.target.value;
                          setFieldValue("type_id", selectedValue);
                          setSelectedType(
                            types.find((type) => type._id === selectedValue)
                              ?.name || ""
                          );
                        }}
                      >
                        <option value="" label="Select Type" />
                        {types.length ? (
                          types.map((type) => (
                            <option key={type._id} value={type._id}>
                              {type.name}
                            </option>
                          ))
                        ) : (
                          <option value="">Loading...</option>
                        )}
                      </Field>
                      <ErrorMessage
                        name="type_id"
                        component="div"
                        className="error-message"
                      />
                    </fieldset>
                  </div>
                  <div className="col-6">
                    <fieldset>
                      <legend>Items</legend>
                      {selectedType === "New" ? (
                        <Field
                          as="select"
                          name="item_id"
                          onChange={(e) => {
                            const selectedValue = e.target.value;
                            setFieldValue("item_id", selectedValue);
                          }}
                        >
                          <option value="" label="Select Item" />
                          {items.length ? (
                            items.map((item) => (
                              <option key={item?._id} value={item?._id}>
                                {item.name}
                              </option>
                            ))
                          ) : (
                            <option value="">No items available</option>
                          )}
                        </Field>
                      ) : selectedType === "Damage" ? (
                        <Field
                          as="select"
                          name="asset_inventory_id"
                          onChange={(e) => {
                            const selectedValue = e.target.value;
                            setFieldValue("asset_inventory_id", selectedValue);
                          }}
                        >
                          <option value="" label="Select Item" />
                          {DamageItems.length ? (
                            DamageItems.map((item) => (
                              <option
                                key={item?.asset_inventory_id}
                                value={item?.asset_inventory_id}
                              >
                                {item.item_name}
                              </option>
                            ))
                          ) : (
                            <option value="">No items available</option>
                          )}
                        </Field>
                      ) : null}
                      <ErrorMessage
                        name={
                          selectedType === "New"
                            ? "item_id"
                            : "asset_inventory_id"
                        }
                        component="div"
                        className="error-message"
                      />
                    </fieldset>
                  </div>
                  <div className="col-6">
                    <fieldset>
                      <legend>Priority</legend>
                      <Field
                        as="select"
                        name="priority_id"
                        onChange={(e) => {
                          const selectedValue = e.target.value;
                          setFieldValue("priority_id", selectedValue);
                        }}
                      >
                        <option value="" label="Select Priority" />
                        {Array.isArray(Priority) && Priority.length > 0 ? (
                          Priority.map((item) => (
                            <option key={item._id} value={item._id}>
                              {item.name}
                            </option>
                          ))
                        ) : (
                          <option value="">No priorities available</option>
                        )}
                      </Field>
                      <ErrorMessage
                        name="priority_id"
                        component="div"
                        className="error-message"
                      />
                    </fieldset>
                  </div>
                  <div className="col-6">
                    <fieldset>
                      <legend>File Upload</legend>
                      <input
                        type="file"
                        name="images"
                        multiple
                        onChange={(event) => {
                          const files = Array.from(event.currentTarget.files);
                          setFieldValue("images", files);
                        }}
                      />
                      <span>{imagePreview}</span>
                      <span
                        style={{
                          fontSize: "38px",
                          position: "absolute",
                          right: "13px",
                          top: "-12px",
                        }}
                      >
                        +
                      </span>
                      <ErrorMessage
                        name="file"
                        component="div"
                        className="error-message"
                      />
                    </fieldset>
                  </div>
                  <div className="col-12">
                    <fieldset>
                      <legend>Comment</legend>
                      <Field as="textarea" name="details" rows="7" />
                      <ErrorMessage
                        name="details"
                        component="div"
                        className="error-message"
                      />
                    </fieldset>
                  </div>
                </div>
                <button
                  disabled={isSubmitting}
                  type="submit"
                  className="btn"
                  style={{
                    margin: "0 auto",
                    display: "block",
                  }}
                >
                  UPDATE
                </button>
              </Form>
            )}
          </Formik>
        )}
        {isSubmitted && <ThankyouModal handleok={handleClosePopup} />}
      </div>
    </div>
  );
};
export default EmpRequestEditForm;
