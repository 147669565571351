import React, { useState, useEffect } from "react";
import axios from "axios";
import Loading from "../common/loading";
import logo from "../../images/logo.svg";
import { useLocation } from "react-router-dom";

const ScanPage = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  // console.log("id...", id);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/qrcodedetails/${id}`);

        setData(response.data.asset_inventory);
      } catch (error) {
        console.error("API Error:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="container">
          <div className="modal scanpage">
            <img src={logo} alt="Lemolite Logo" className="logo" />
            {/* <img src={atslogo} alt="ats title Logo" className="logo" /> */}
            <h1 className="atstitle">Asset Tracking Management</h1>

            <div className="modal-content">
              <span className="close-icon"></span>
              <p>
                <strong>Allocated To: </strong>
                {data?.allocated_to.name}
                {/* {data.is_allocate ? "Yes" : "No"} */}
              </p>
              <p>
                <strong>Type : </strong>
                {data?.type?.type_name || "N/A"}
              </p>

              <p>
                <strong>Device: </strong>
                {data?.item?.item_name || "N/A"}
              </p>
              <p>
                <strong>Purchased At : </strong>
                {data?.purchase_date || "N/A"}
              </p>

              <p className="d-flex">
                <strong style={{ whiteSpace: "pre" }}>Device Details : </strong>
                <p className="" style={{ maxWidth: "320px" }}>
                  {data?.details || "N/A"}
                </p>
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ScanPage;
