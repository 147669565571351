import React from "react";
import PropTypes from "prop-types";
import closeicon from "../../../images/close-icon.svg";
const RequestItemViewModel = ({ item, onClose }) => {
  // console.log("item...", item);
  return (
    <div className=" img_model modal">
      <div className=" modal_content ">
        {item.type.name === "Damage" && (
          <div className="img_secs w-50">
            <img
              src={`/${item.asset_inventory.qrcode_path}`}
              alt="QR Code"
              width="200px"
              height="200px"
            />
          </div>
        )}
        {item.type.name === "New" && item.images.length > 0 && (
          <div className="img_secs w-50">
            <img
              src={`/${item.images[0]}`}
              alt="QR Code"
              width="200px"
              height="200px"
              style={{
                objectFit: "cover",
              }}
            />
          </div>
        )}
        {/* {item?.images?.length > 0 && (
          <div className="img_secs w-50">
            <img
              src={`/${item.images}`}
              alt="QR Code"
              width="100%"
              height="100%"
            />
          </div>
        )} */}
        <div className="content w-50">
          <span className="close-icon" onClick={onClose}>
            <img src={closeicon} alt="New" className="" />
          </span>
          <p>
            <strong>Name: </strong> {item.user?.name}
          </p>
          {/* <p>
            <strong>Department :</strong> {item.department?.name}
          </p> */}

          <p>
            <strong>Type: </strong> {item.type?.name}
          </p>
          <p>
            <strong>Items: </strong>
            {item?.type.name === "New"
              ? item.item?.item_name || "-"
              : item.asset_inventory?.item_name || "-"}
          </p>
          <p>
            <strong>Priority: </strong> {item.priority?.name}
          </p>
        </div>
        <fieldset className="w-100">
          <legend>Comments</legend>
          <textarea
            type="textarea"
            ame="details"
            rows="2"
            cols="3"
            value={item.details}
            readOnly
          ></textarea>
        </fieldset>
        {item.type.name === "Damage" && item.images.length > 0 && (
          <div className="row w-100">
            {item.images.map((image, index) => (
              <div className="col-4">
                <div className="img_secs" key={index}>
                  <img
                    src={`/${image}`}
                    alt={`Image ${index}`}
                    width="95px"
                    height="95px"
                    style={{
                      objectFit: "cover",
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
RequestItemViewModel.propTypes = {
  item: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    item: PropTypes.shape({
      item_name: PropTypes.string.isRequired,
    }).isRequired,
    purchase_date: PropTypes.string.isRequired,
    type: PropTypes.shape({
      type_name: PropTypes.string.isRequired,
    }).isRequired,
    qrcode_path: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};
export default RequestItemViewModel;

{
  /* <img src={`/${item.qrcode_path}`} alt="" width="100%" height="100%" /> */
}
