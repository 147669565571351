import React, { useState, useEffect } from "react";
import SearchbarTop from "../../common/SearchbarTop";
import view from "../../../images/view.svg";
import editIcon from "../../../images/edit.svg";
import deleteIcon from "../../../images/deletee.svg";
import axios from "axios";
import moment from "moment";
import Pagination from "../../common/Pagination";
import ItemViewModal from "../ItemViewModal";
import { useNavigate } from "react-router-dom";
import newicon from "../../../images/newicon.svg";
import damageicon from "../../../images/damageicon.svg";
import Loading from "../../common/loading";
import ScanImgModel from "../../common/ScanImgModel";
import { toast } from "react-toastify";
import DeleteItemModal from "../../common/DeleteItemModal";
import ThankYouModal from "../../common/ThankyouModal";
import FormattedDate from "../../common/FormattedDate";

const Inventory = () => {
  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState({ inventory: [] });
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [modalData, setModalData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isImgModalOpen, setIsImgModalOpen] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isThankYouModalOpen, setIsThankYouModalOpen] = useState(false); // Thank-you modal state
  const navigate = useNavigate();
  console.log("datainventry..", data);

  const fetchData = async () => {
    try {
      const response = await axios.get("/api/get_asset");
      const sortedData = response.data.inventory.sort((a, b) => {
        return new Date(b.updated_at) - new Date(a.updated_at);
      });
      setData({ inventory: sortedData });
      setFilteredData(sortedData); // Update to use sorted inventory
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const fetchDataById = async () => {
      if (selectedItemId) {
        try {
          const response = await axios.get(
            `/api/get_asset_by_id/${selectedItemId}`
          );
          setModalData(response.data.asset);
        } catch (error) {
          console.error("Failed to fetch item data:", error);
        }
      }
    };
    fetchDataById();
  }, [selectedItemId]);

  const handleSearchInputChange = (searchInput) => {
    const lowerCaseInput = searchInput.toLowerCase();
    const filterData = (item) => {
      const valuesToSearch = [
        item.item.item_name,
        item.purchase_date,

        item.type.type_name,
      ]
        .join(" ")
        .toLowerCase();
      return valuesToSearch.includes(lowerCaseInput);
    };
    const newFilteredData = data.inventory.filter(filterData);
    setFilteredData(newFilteredData);
    setCurrentPage(1); // Reset to the first page when search is performed
  };

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const currentItems = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleViewItemClick = (id) => {
    setSelectedItemId(id);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalData(null);
    setSelectedItemId(null);
  };

  const openImgModal = (id) => {
    setSelectedItemId(id);
    setIsImgModalOpen(true);
    setIsModalOpen(false);
  };

  const closeImgModal = () => {
    setIsImgModalOpen(false);
  };

  const handleEditItemClick = async (id) => {
    navigate(`/hr/inventory/inventryedit/${id}`);
  };

  const addrequest = () => {
    navigate("/hr/inventory/inventoryadditem");
  };

  const assestview = () => {
    navigate("/hr/inventory/assest");
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`/api/delete_inventory/${id}`);
      if (response.status === 200) {
        const { message } = response.data;
        toast.success(message);
      }
      fetchData();
    } catch (error) {
      console.error("Failed to delete item:", error);
    } finally {
      setIsDeleteModalVisible(false);
    }
  };

  return (
    <div className="section">
      <div className="container">
        <SearchbarTop
          title="Manage Inventory"
          onSearchInputChange={handleSearchInputChange}
        />
        <div className="action_btns">
          <button className="btn" onClick={addrequest}>
            ADD ITEMS
          </button>
          <button className="btn" onClick={assestview}>
            ASSETS
          </button>
        </div>
        {loading ? (
          <Loading />
        ) : (
          <>
            {currentItems.length > 0 ? (
              <>
                <div className="table-container">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>ITEMS</th>
                        <th>QR CODE</th>
                        <th>PURCHASE DATE</th>
                        <th>TYPE</th>
                        <th>ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems.map((invetry, index) => (
                        <tr key={index} className="table_tr">
                          <td>
                            {(currentPage - 1) * itemsPerPage + index + 1}
                          </td>
                          <td>{invetry.item.item_name}</td>
                          <td>
                            <img
                              onClick={() => openImgModal(invetry._id)}
                              src={`/${invetry.qrcode_path}`}
                              alt="qrcode"
                              className="action_icon"
                              width="40px"
                              height="40px"
                            />
                          </td>
                          <td>
                            <FormattedDate date={invetry.purchase_date} />
                          </td>

                          <td>
                            {invetry.type.type_name === "New" ? (
                              <div className="btn_type new_button">
                                <img src={newicon} alt="New" className="icon" />
                                <h6> New</h6>
                              </div>
                            ) : invetry.type.type_name === "Damage" ? (
                              <div className="btn_type damage_button">
                                <img
                                  src={damageicon}
                                  alt="Damaged"
                                  className="icon"
                                />
                                <h6> Damaged</h6>
                              </div>
                            ) : null}
                          </td>
                          <td>
                            <img
                              src={view}
                              alt="view"
                              className="view action_icon"
                              onClick={() => handleViewItemClick(invetry._id)}
                            />
                            <img
                              src={editIcon}
                              alt="edit"
                              className="editIcon action_icon"
                              onClick={() => handleEditItemClick(invetry._id)}
                            />
                            <img
                              src={deleteIcon}
                              alt="delete"
                              className="deleteIcon action_icon"
                              onClick={() => {
                                setModalData(invetry);
                                setIsDeleteModalVisible(true);
                              }}
                              style={{ cursor: "pointer" }}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {!searchInput && (
                  <Pagination
                    currentPage={currentPage}
                    totalItems={filteredData.length}
                    itemsPerPage={itemsPerPage}
                    onPageChange={handlePageChange}
                  />
                )}
              </>
            ) : (
              <h2 className="data_noavailble">Data Not Available</h2>
            )}

            {isModalOpen && modalData && !isImgModalOpen && (
              <ItemViewModal item={modalData} onClose={handleCloseModal} />
            )}
            {isImgModalOpen && modalData && (
              <ScanImgModel item={modalData} onClose={closeImgModal} />
            )}
            {isDeleteModalVisible && (
              <DeleteItemModal
                data={modalData}
                onDelete={() => handleDelete(modalData._id)}
                onClose={() => setIsDeleteModalVisible(false)}
              />
            )}
            {isThankYouModalOpen && (
              <ThankYouModal onClose={() => setIsThankYouModalOpen(false)} />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Inventory;
