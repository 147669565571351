import React from "react";
import PropTypes from "prop-types";
import closeicon from "../../../images/close-icon.svg";
import moment from "moment";

const AssignViewModel = ({ item, onClose }) => {
  // console.log("itemassign..", item);

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close-icon" onClick={onClose}>
          <img src={closeicon} alt="New" className="" />
        </span>
        <p>
          <strong>Name : </strong>
          {item.user_id?.name}
        </p>

        {item.department.name && (
          <p>
            <strong>Department : </strong>
            {item.department.name}
          </p>
        )}
        {item.asset_inventory.length > 0 && (
          <p>
            <strong>Item : </strong>
            {item.asset_inventory
              ? item.asset_inventory.map((asset) => asset.item_name).join(", ")
              : "-"}
          </p>
        )}

        <p>
          <strong>
            Allocated : {moment(item.created_at).format("DD-MM-YYYY")}
          </strong>
        </p>
      </div>
    </div>
  );
};

AssignViewModel.propTypes = {
  item: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    item: PropTypes.shape({
      item_name: PropTypes.string.isRequired,
    }).isRequired,
    purchase_date: PropTypes.string.isRequired,
    type: PropTypes.shape({
      type_name: PropTypes.string.isRequired,
    }).isRequired,
    qrcode_path: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AssignViewModel;
