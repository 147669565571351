import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import goback from "../../images/go back.svg";
import { useState } from 'react';
import searchIcon from "../../images/search-icon.svg";
import arrow from "../../images/leftarrowsvg.svg"
import { useLocation } from "react-router-dom";




const SearchbarTop = ({ title, onSearchInputChange }) => {
  const navigate = useNavigate(); 
  const location = useLocation();
  const [searchInput, setSearchInput] = useState("");
  const [data, setData] = useState([]); 
  const [filteredData, setFilteredData] = useState([]);
  const isEditPage = location.pathname.startsWith('/employee/request/addrequest/');


  const handleGoBack = () => {
    navigate(-1); 
  };
  const filterData = (input) => {
    const filtered = data.filter((item) =>
      item.name.toLowerCase().includes(input.toLowerCase())
    );
    setFilteredData(filtered);
  };
  const handleSearchInputChange = (event) => {
    const searchInput = event.target.value;
    setSearchInput(searchInput);
    onSearchInputChange(searchInput);
  };
  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
  };
  return (
    <> 
     <Link to='https://hrems.lemolite.com/dashboard/time-attendance' className='d-flex align-items-center justify-content-end'>
        <img src={arrow} alt="arrow" width="" height=""/>
      <h4 className='heading4 blue-color fw-bolder m-0 ml-2'>        
        GO BACK TO HREMS</h4>
        </Link>
        <div className="searchbar-top">
      <div className="content-title">
        {title}
      </div>
      {isEditPage ? (
        <button onClick={handleBackClick} className="back-button">
        Back
      </button>
      ) : (
        <div className="search_bar">
          <input
            type="text"
            value={searchInput}
            onChange={handleSearchInputChange}
            placeholder="Search..."
            className="search-input"
          />
          <img src={searchIcon} alt="search" className="search-icon" />
        </div>
      )}
    </div>
    </>

  );
};

export default SearchbarTop;
