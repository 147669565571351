import React, { useState, useEffect } from "react";
import SearchbarTop from "../../common/SearchbarTop";
import view from "../../../images/view.svg";
import deleteIcon from "../../../images/deletee.svg";
import axios from "axios";
import moment from "moment";
import Pagination from "../../common/Pagination";
import { useNavigate } from "react-router-dom";
import newicon from "../../../images/newicon.svg";
import Loading from "../../common/loading";
import EmpviewModel from "./EmpviewModel";

const EmpItem = () => {
  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [modalData, setModalData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/api/get_my_allocated_assetitems");

        // Sort the data in descending order based on 'created_at' field
        const sortedData = response.data.user_assetitems.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });

        setData(sortedData);
        setFilteredData(sortedData);
        setTotalItems(sortedData.length);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleSearchInputChange = (searchInput) => {
    setSearchInput(searchInput);

    const lowerCaseInput = searchInput.toLowerCase();
    const newFilteredData = data.filter((item) =>
      JSON.stringify(item).toLowerCase().includes(lowerCaseInput)
    );

    setFilteredData(newFilteredData);
    setCurrentPage(1);

    setTotalItems(newFilteredData.length);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleViewItemClick = (inventoryItem) => {
    setModalData(inventoryItem);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalData(null);
  };

  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="section">
      <div className="container">
        <SearchbarTop
          title="Employee List"
          onSearchInputChange={handleSearchInputChange}
        />

        {loading ? (
          <Loading />
        ) : (
          <>
            {paginatedData.length > 0 ? (
              <div className="table-container">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">ITEMS</th>
                      <th scope="col">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedData.map((item, index) => (
                      <React.Fragment key={item._id}>
                        {item.asset_inventory.map((inventoryItem, subIndex) => (
                          <tr
                            key={`${item._id}-${subIndex}`}
                            className="table_tr"
                          >
                            <td>
                              {(currentPage - 1) * itemsPerPage + index + 1}
                            </td>
                            <td>{inventoryItem.item_name}</td>
                            <td>
                              <img
                                src={view}
                                alt="view"
                                className="view action_icon"
                                onClick={() =>
                                  handleViewItemClick(inventoryItem)
                                }
                                style={{ cursor: "pointer" }}
                              />
                            </td>
                          </tr>
                        ))}
                      </React.Fragment>
                    ))}
                    {isModalOpen && modalData && (
                      <EmpviewModel
                        item={modalData}
                        onClose={handleCloseModal}
                      />
                    )}
                  </tbody>
                </table>
              </div>
            ) : (
              <h2 className="data_noavailble">Data Not Available</h2>
            )}
            <Pagination
              currentPage={currentPage}
              totalItems={totalItems}
              itemsPerPage={itemsPerPage}
              onPageChange={handlePageChange}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default EmpItem;
